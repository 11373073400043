@charset "UTF-8";
.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all; }

.swal2-popup.swal2-toast > * {
  grid-column: 2; }

.swal2-popup.swal2-toast .swal2-title {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center; }

.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: .5em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-footer {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em; }

.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: .8em;
  height: .8em;
  margin: 0;
  font-size: 2em; }

.swal2-popup.swal2-toast .swal2-html-container {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0; }

.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: .25em; }

.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 .5em 0 0; }

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: 700; }

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: .875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: .3125em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: .3125em; }

.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: .5em;
  padding: 0 .5em; }

.swal2-popup.swal2-toast .swal2-styled {
  margin: .25em .5em;
  padding: .4em .6em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.25em;
  left: .9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: .3125em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: .1875em;
  width: .75em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: .9375em;
  right: .1875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-toast-animate-success-line-tip .75s;
  animation: swal2-toast-animate-success-line-tip .75s; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-toast-animate-success-line-long .75s;
  animation: swal2-toast-animate-success-line-long .75s; }

.swal2-popup.swal2-toast.swal2-show {
  -webkit-animation: swal2-toast-show .5s;
  animation: swal2-toast-show .5s; }

.swal2-popup.swal2-toast.swal2-hide {
  -webkit-animation: swal2-toast-hide .1s forwards;
  animation: swal2-toast-hide .1s forwards; }

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: .625em;
  overflow-x: hidden;
  transition: background-color .1s;
  -webkit-overflow-scrolling: touch; }

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4); }

.swal2-container.swal2-backdrop-hide {
  background: 0 0 !important; }

.swal2-container.swal2-bottom-start, .swal2-container.swal2-center-start, .swal2-container.swal2-top-start {
  grid-template-columns: minmax(0, 1fr) auto auto; }

.swal2-container.swal2-bottom, .swal2-container.swal2-center, .swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto; }

.swal2-container.swal2-bottom-end, .swal2-container.swal2-center-end, .swal2-container.swal2-top-end {
  grid-template-columns: auto auto minmax(0, 1fr); }

.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start; }

.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center; }

.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end; }

.swal2-container.swal2-center-left > .swal2-popup, .swal2-container.swal2-center-start > .swal2-popup {
  grid-row: 2;
  align-self: center; }

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center; }

.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end; }

.swal2-container.swal2-bottom-left > .swal2-popup, .swal2-container.swal2-bottom-start > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end; }

.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end; }

.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end; }

.swal2-container.swal2-grow-fullscreen > .swal2-popup, .swal2-container.swal2-grow-row > .swal2-popup {
  grid-column: 1/4;
  width: 100%; }

.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch; }

.swal2-container.swal2-no-transition {
  transition: none !important; }

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem; }

.swal2-popup:focus {
  outline: 0; }

.swal2-popup.swal2-loading {
  overflow-y: hidden; }

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word; }

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0; }

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4; }

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: .25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent; }

.swal2-styled {
  margin: .3125em;
  padding: .625em 1.1em;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500; }

.swal2-styled:not([disabled]) {
  cursor: pointer; }

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5); }

.swal2-styled.swal2-deny {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5); }

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgba(110, 120, 129, 0.5); }

.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-styled:focus {
  outline: 0; }

.swal2-styled::-moz-focus-inner {
  border: 0; }

.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em; }

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  height: .25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.swal2-timer-progress-bar {
  width: 100%;
  height: .25em;
  background: rgba(0, 0, 0, 0.2); }

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em; }

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color .1s,box-shadow .1s;
  border: none;
  border-radius: 5px;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end; }

.swal2-close:hover {
  transform: none;
  background: 0 0;
  color: #f27474; }

.swal2-close:focus {
  outline: 0;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-close::-moz-focus-inner {
  border: 0; }

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em .3em;
  padding: 0;
  overflow: auto;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word; }

.swal2-checkbox, .swal2-file, .swal2-input, .swal2-radio, .swal2-select, .swal2-textarea {
  margin: 1em 2em 0; }

.swal2-file, .swal2-input, .swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color .1s,box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px transparent;
  color: inherit;
  font-size: 1.125em; }

.swal2-file.swal2-inputerror, .swal2-input.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-file::-moz-placeholder, .swal2-input::-moz-placeholder, .swal2-textarea::-moz-placeholder {
  color: #ccc; }

.swal2-file:-ms-input-placeholder, .swal2-input:-ms-input-placeholder, .swal2-textarea:-ms-input-placeholder {
  color: #ccc; }

.swal2-file::placeholder, .swal2-input::placeholder, .swal2-textarea::placeholder {
  color: #ccc; }

.swal2-range {
  margin: 1em 2em 0;
  background: #fff; }

.swal2-range input {
  width: 80%; }

.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center; }

.swal2-range input, .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em; }

.swal2-input {
  height: 2.625em;
  padding: 0 .75em; }

.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 1.125em; }

.swal2-textarea {
  height: 6.75em;
  padding: .75em; }

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em; }

.swal2-checkbox, .swal2-radio {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit; }

.swal2-checkbox label, .swal2-radio label {
  margin: 0 .6em;
  font-size: 1.125em; }

.swal2-checkbox input, .swal2-radio input {
  flex-shrink: 0;
  margin: 0 .4em; }

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0; }

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: .625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300; }

.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center; }

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto .6em;
  border: .25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em; }

.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-error.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark .5s;
  animation: swal2-animate-error-x-mark .5s; }

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86; }

.swal2-icon.swal2-warning.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .5s;
  animation: swal2-animate-i-mark .5s; }

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee; }

.swal2-icon.swal2-info.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .8s;
  animation: swal2-animate-i-mark .8s; }

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd; }

.swal2-icon.swal2-question.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-question-mark .8s;
  animation: swal2-animate-question-mark .8s; }

.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0; }

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%; }

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: .8125em;
  width: 1.5625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip .75s;
  animation: swal2-animate-success-line-tip .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long .75s;
  animation: swal2-animate-success-line-long .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in; }

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600; }

.swal2-progress-steps li {
  display: inline-block;
  position: relative; }

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6; }

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #2778c4; }

[class^=swal2] {
  -webkit-tap-highlight-color: transparent; }

.swal2-show {
  -webkit-animation: swal2-show .3s;
  animation: swal2-show .3s; }

.swal2-hide {
  -webkit-animation: swal2-hide .15s forwards;
  animation: swal2-hide .15s forwards; }

.swal2-noanimation {
  transition: none; }

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0; }

.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto; }

@-webkit-keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@-webkit-keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@-webkit-keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@-webkit-keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@-webkit-keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@-webkit-keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@-webkit-keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden; }

body.swal2-height-auto {
  height: auto !important; }

body.swal2-no-backdrop .swal2-container {
  background-color: transparent !important;
  pointer-events: none; }

body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all; }

body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important; } }

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none; }

body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }

body.swal2-toast-shown .swal2-container.swal2-top-left, body.swal2-toast-shown .swal2-container.swal2-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-center-left, body.swal2-toast-shown .swal2-container.swal2-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%); }

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-left, body.swal2-toast-shown .swal2-container.swal2-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto; }

/* This stylesheet generated by Transfonter (https://transfonter.org) on February 25, 2018 4:00 PM */
@font-face {
  font-family: 'Golos';
  src: url("../fonts/GolosText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Golos';
  src: url("../fonts/GolosTextVF-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/PTSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Golos';
  src: url("../fonts/GolosText-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Golos';
  src: url("../fonts/GolosText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Golos';
  src: url("../fonts/GolosText-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Golos';
  src: url("../fonts/GolosText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

body {
  min-height: 100vh;
  margin: 0;
  color: #000;
  font-family: 'PT Sans';
  display: flex;
  flex-direction: column;
  position: relative; }
  body.opened {
    overflow: hidden; }

main {
  position: relative;
  flex: 1 0 auto; }

p, ol, ul, h1, h2, h3, button, form {
  margin: 0;
  padding: 0; }

button, input, textarea {
  font-family: inherit; }
  button:focus, input:focus, textarea:focus {
    outline: none; }

input {
  min-width: 0;
  font-size: inherit;
  appearance: none; }

ul, li {
  list-style-type: none; }

input::-webkit-input-placeholder {
  font-size: 15px;
  line-height: 18px;
  color: #999999; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

a {
  color: #000;
  text-decoration: none; }

svg {
  vertical-align: middle;
  transition: fill 0.3s; }

picture {
  display: block; }

img {
  display: block; }

source {
  display: none; }

*, ::before, ::after {
  box-sizing: border-box; }

:focus {
  outline: none; }

.container {
  max-width: 1803px;
  margin: 0 auto; }
  @media (max-width: 1919px) {
    .container {
      max-width: calc(0.93906 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .container {
      max-width: calc(100% - 30px); } }

.container-2 {
  max-width: 1758px;
  margin: 0 auto; }
  @media (max-width: 1919px) {
    .container-2 {
      max-width: calc(0.91563 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .container-2 {
      max-width: calc(100% - 30px); } }

#page-container {
  padding: 0 0 45px; }
  @media (max-width: 1919px) {
    #page-container {
      padding: 0 0 calc(0.02344 * 100vw + 0px); } }
  @media (max-width: 991px) {
    #page-container {
      padding: 0 0 30px; } }

em {
  font-style: normal;
  color: #E64569; }

.loading {
  filter: blur(3px);
  pointer-events: none;
  user-select: none; }

.btn {
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn--icon {
    background-color: transparent;
    transition: all .3s ease; }
    .btn--icon:hover svg {
      stroke: #000; }
    .btn--icon svg {
      width: 33px;
      height: 33px;
      fill: #B6B6B6;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .btn--icon svg {
          width: calc(0.02083 * 100vw + 0px);
          height: calc(0.02083 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--icon svg {
          width: 16px;
          height: 16px; } }
  .btn--filled {
    transition: background-color 0.3s;
    background-color: #000;
    border: 1px solid #fff;
    transition: all .3s ease;
    border-radius: 12px; }
    @media (max-width: 1919px) {
      .btn--filled {
        border-radius: calc(0.00625 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .btn--filled {
        border-radius: 5px; } }
    .btn--filled span {
      font-family: 'Golos';
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #fff;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .btn--filled span {
          font-size: calc(0.0125 * 100vw + 0px);
          line-height: calc(0.01562 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--filled span {
          font-size: 14px;
          line-height: 125%; } }
    .btn--filled svg {
      fill: #fff;
      transition: all .3s ease;
      width: 30px;
      height: 30px;
      margin-bottom: -2px; }
      @media (max-width: 1919px) {
        .btn--filled svg {
          width: calc(0.01562 * 100vw + 0px);
          height: calc(0.01562 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--filled svg {
          width: 17px;
          height: 17px; } }
    .btn--filled:hover {
      background-color: #fff;
      border-color: #000; }
      .btn--filled:hover svg {
        fill: #000; }
      .btn--filled:hover span {
        color: #000; }
  .btn--reverse {
    background-color: #fff;
    border: 1.5px solid #000;
    transition: all .3s ease;
    border-radius: 12px; }
    @media (max-width: 1919px) {
      .btn--reverse {
        border-radius: calc(0.00625 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .btn--reverse {
        border-radius: 5px; } }
    .btn--reverse span {
      font-family: 'Golos';
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #000;
      transition: color .3s ease; }
      @media (max-width: 1919px) {
        .btn--reverse span {
          font-size: calc(0.0125 * 100vw + 0px);
          line-height: calc(0.01562 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--reverse span {
          font-family: 'Golos';
          font-size: 14px;
          line-height: 125%; } }
    .btn--reverse svg {
      fill: #fff;
      transition: all .3s ease;
      width: 30px;
      height: 30px;
      margin-bottom: -2px;
      fill: #000; }
      @media (max-width: 1919px) {
        .btn--reverse svg {
          width: calc(0.01562 * 100vw + 0px);
          height: calc(0.01562 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--reverse svg {
          width: 16px;
          height: 16px; } }
    .btn--reverse:hover {
      background-color: #000; }
      .btn--reverse:hover span {
        color: #fff; }
      .btn--reverse:hover svg {
        fill: #fff; }
  .btn--link {
    font-family: 'Golos';
    font-size: 24px;
    line-height: 30px;
    color: #0F20E5;
    transition: all .3s ease;
    text-decoration: underline; }
    @media (max-width: 1919px) {
      .btn--link {
        font-size: calc(0.0125 * 100vw + 0px);
        line-height: calc(0.01562 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .btn--link {
        font-family: 'Golos';
        font-size: 14px;
        line-height: 125%; } }
    .btn--link:hover {
      opacity: .75; }
  .btn--pink {
    border-radius: 12px;
    background-color: #FF1493;
    border: 1px solid transparent;
    transition: all .3s ease; }
    @media (max-width: 1919px) {
      .btn--pink {
        border-radius: calc(0.00625 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .btn--pink {
        border-radius: 5px;
        height: 34px; } }
    .btn--pink span {
      font-family: 'Golos';
      font-size: 24px;
      line-height: 30px;
      color: #fff;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .btn--pink span {
          font-size: calc(0.0125 * 100vw + 0px);
          line-height: calc(0.01562 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--pink span {
          font-size: 14px;
          line-height: 125%;
          margin-bottom: 2px; } }
    .btn--pink svg {
      width: 29px;
      height: 29px;
      fill: transparent;
      stroke: #fff; }
      @media (max-width: 1919px) {
        .btn--pink svg {
          width: calc(0.0151 * 100vw + 0px);
          height: calc(0.0151 * 100vw + 0px); } }
    .btn--pink:hover {
      border-color: #FF1493;
      background-color: transparent; }
      .btn--pink:hover span {
        color: #FF1493; }
      .btn--pink:hover svg {
        stroke: #FF1493; }
  .btn--pink-reversed {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #FF1493;
    transition: all .3s ease; }
    .btn--pink-reversed:hover {
      background-color: #FF1493; }
      .btn--pink-reversed:hover span {
        color: #fff; }
      .btn--pink-reversed:hover svg {
        stroke: #fff; }
    @media (max-width: 1919px) {
      .btn--pink-reversed {
        border-radius: calc(0.00625 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .btn--pink-reversed {
        border-radius: 5px; } }
    .btn--pink-reversed span {
      font-family: 'Golos';
      font-size: 24px;
      line-height: 125%;
      color: #FF1493;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .btn--pink-reversed span {
          font-size: calc(0.0125 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--pink-reversed span {
          font-size: 14px; } }
    .btn--pink-reversed svg {
      width: 29px;
      height: 29px;
      fill: transparent;
      stroke: #FF1493;
      margin-left: 7px;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .btn--pink-reversed svg {
          width: calc(0.0151 * 100vw + 0px);
          height: calc(0.0151 * 100vw + 0px);
          margin-left: calc(0.00365 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--pink-reversed svg {
          width: 16px;
          height: 16px;
          margin-left: 5px; } }
  .btn--black-reversed {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 11px;
    transition: all .3s ease; }
    @media (max-width: 1919px) {
      .btn--black-reversed {
        border-radius: calc(0.00573 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .btn--black-reversed {
        border-radius: 5px; } }
    .btn--black-reversed:hover {
      background-color: #000; }
      .btn--black-reversed:hover span {
        color: #fff; }
      .btn--black-reversed:hover svg {
        fill: #fff; }
    .btn--black-reversed span {
      font-family: 'Golos';
      font-weight: 400;
      font-size: 24px;
      line-height: 125%;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .btn--black-reversed span {
          font-size: calc(0.0125 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--black-reversed span {
          font-size: 14px; } }
    .btn--black-reversed svg {
      fill: transparent;
      stroke: #000;
      width: 15px;
      height: 16px;
      margin-left: 14px; }
      @media (max-width: 1919px) {
        .btn--black-reversed svg {
          width: calc(0.00781 * 100vw + 0px);
          height: calc(0.00833 * 100vw + 0px);
          margin-left: calc(0.00729 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .btn--black-reversed svg {
          width: 20px;
          height: 10px;
          margin-left: 11px; } }

.d-flex-row {
  display: flex;
  flex-direction: row; }

.js-open-other.opened .js-hidden {
  display: block; }

.js-open-other .js-hidden {
  display: none; }

.open-other {
  cursor: pointer;
  margin: 20px 0 0; }
  .open-other:hover span {
    border-color: transparent; }
  .open-other span {
    font-size: 15px;
    line-height: 18px;
    border-bottom: 1px solid #999999;
    color: #999999;
    transition: border-color 0.3s; }

.lead-phone svg {
  width: 15px;
  height: 15px;
  fill: #E64569;
  margin-right: 8px; }

.lead-phone span {
  font-size: 15px;
  line-height: 18px;
  color: #000; }

.breadcrumbs {
  padding: 19px 0 20px; }
  @media (max-width: 1919px) {
    .breadcrumbs {
      padding: calc(0.0099 * 100vw + 0px) calc(0.01042 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .breadcrumbs {
      padding: 10px 0 15px; } }
  .breadcrumbs-wrapper {
    margin-bottom: 30px; }
    @media (max-width: 1919px) {
      .breadcrumbs-wrapper {
        margin-bottom: calc(0.01562 * 100vw + 0px); } }
  .breadcrumbs-item {
    font-family: 'PT Sans';
    font-weight: 400;
    font-size: 17.5465px;
    line-height: 125%;
    color: #000;
    margin-right: 2px; }
    @media (max-width: 1919px) {
      .breadcrumbs-item {
        font-size: calc(0.00914 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .breadcrumbs-item {
        font-size: 11px; } }
    .breadcrumbs-item.last {
      color: #B6B6B6; }
    .breadcrumbs-item svg {
      fill: transparent;
      stroke: #B6B6B6;
      width: 9px;
      height: 9px;
      transform: rotate(-90deg); }
      @media (max-width: 1919px) {
        .breadcrumbs-item svg {
          width: calc(0.00469 * 100vw + 0px);
          height: calc(0.00469 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .breadcrumbs-item svg {
          width: 6px;
          height: 6px; } }
  .breadcrumbs-back {
    display: flex;
    align-items: center;
    margin: 15px 0 0 23px;
    padding: 11px 38px 16px 38px;
    border-radius: 12px;
    width: fit-content;
    box-shadow: 1.46px 1.46px 8.77px 0px #00000029; }
    @media (max-width: 1919px) {
      .breadcrumbs-back {
        margin: calc(0.00781 * 100vw + 0px) 0 0 calc(0.01198 * 100vw + 0px);
        padding: calc(0.00573 * 100vw + 0px) calc(0.01979 * 100vw + 0px) calc(0.00833 * 100vw + 0px) calc(0.01979 * 100vw + 0px);
        border-radius: calc(0.00625 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .breadcrumbs-back {
        margin: 10px 0 0;
        padding: 5px 8px;
        border-radius: 5px; } }
    .breadcrumbs-back:hover svg {
      fill: #E64569; }
    .breadcrumbs-back:hover span {
      color: #E64569; }
    .breadcrumbs-back svg {
      width: 29px;
      height: 29px;
      fill: #757575;
      margin-right: 7px;
      transition: fill 0.3s; }
      @media (max-width: 1919px) {
        .breadcrumbs-back svg {
          width: calc(0.0151 * 100vw + 0px);
          height: calc(0.0151 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .breadcrumbs-back svg {
          width: 10px;
          height: 10px; } }
    .breadcrumbs-back span {
      font-family: 'PT Sans';
      font-size: 38px;
      line-height: 125%;
      color: #757575;
      transition: color 0.3s; }
      @media (max-width: 1919px) {
        .breadcrumbs-back span {
          font-size: calc(0.01979 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .breadcrumbs-back span {
          font-size: 11px; } }

.page-title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 0 35px; }
  .page-title.category-title {
    margin: -74px 0 50px 26.1%; }
    @media (max-width: 1919px) {
      .page-title.category-title {
        margin: calc(-0.03854 * 100vw + 0px) 0 calc(0.02604 * 100vw + 0px) 26.1%; } }
    @media (max-width: 991px) {
      .page-title.category-title {
        margin: 0 0 16px; } }
  @media (max-width: 1919px) {
    .page-title {
      margin: 0 0 calc(0.01823 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .page-title {
      margin: 0 0 25px; } }
  .page-title--line {
    align-items: center;
    justify-content: space-between; }
  .page-title--notify {
    flex-direction: column; }
  .page-title__name {
    margin-right: 15px; }
    @media (max-width: 1919px) {
      .page-title__name {
        margin-right: calc(0.00781 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .page-title__name {
        margin-right: 10px; } }
    .page-title__name h1, .page-title__name span {
      font-family: 'Golos';
      font-weight: 600;
      font-size: 39px;
      line-height: 120%;
      color: #000; }
      @media (max-width: 1919px) {
        .page-title__name h1, .page-title__name span {
          font-size: calc(0.02031 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .page-title__name h1, .page-title__name span {
          font-size: 18px; } }
  .page-title__link {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000;
    position: relative;
    transition: color 0.3s; }
    .page-title__link:hover {
      color: #E64569; }
    .page-title__link::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: #E64569;
      position: absolute;
      border-radius: 100%;
      left: -15px;
      top: 0;
      bottom: 0;
      margin: auto; }
  .page-title__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
  .page-title__notify {
    margin: 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 767px) {
      .page-title__notify {
        flex-direction: column;
        align-items: flex-start; } }
    .page-title__notify-text {
      font-size: 15px;
      line-height: 24px;
      color: #282828; }
    .page-title__notify-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #E64569; }

.fake-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content; }
  .fake-checkbox:hover div p {
    color: #000; }
  .fake-checkbox:hover div span {
    border-color: #FF1493; }
  .fake-checkbox input {
    display: none; }
    .fake-checkbox input:checked + div span {
      border-color: #FF1493; }
      .fake-checkbox input:checked + div span p {
        background-color: #FF1493; }
    .fake-checkbox input:checked + div p {
      color: #000; }
  .fake-checkbox div {
    display: flex;
    align-items: center; }
  .fake-checkbox span {
    border: 1px solid #818181;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    aspect-ratio: 1/1;
    margin-right: 16px; }
    @media (max-width: 1919px) {
      .fake-checkbox span {
        margin-right: calc(0.00833 * 100vw + 0px);
        width: calc(0.01302 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .fake-checkbox span {
        margin-right: 8px;
        width: 12px; } }
    .fake-checkbox span p {
      width: 13px;
      aspect-ratio: 1/1;
      border-radius: 50%; }
      @media (max-width: 1919px) {
        .fake-checkbox span p {
          width: calc(0.00677 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .fake-checkbox span p {
          width: 8px; } }
  .fake-checkbox p {
    font-family: 'PT Sans';
    font-size: 21px;
    line-height: 140%;
    color: #565656; }
    @media (max-width: 1919px) {
      .fake-checkbox p {
        font-size: calc(0.01094 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .fake-checkbox p {
        font-size: 14px; } }

.swiper {
  transition: opacity 0.3s;
  opacity: 1; }
  .swiper-init {
    opacity: 0; }
  .swiper-nav {
    cursor: pointer; }
    .swiper-nav--next svg {
      transform: rotate(180deg); }
    .swiper-nav svg {
      width: 36px;
      height: 25px;
      fill: #999999;
      transition: fill 0.3s; }
    .swiper-nav:hover svg {
      fill: #282828; }
  .swiper-pagination {
    z-index: 1; }
    .swiper-pagination-bullet {
      margin: 0 10px;
      width: 10px;
      height: 10px;
      background-color: #6A6A6A;
      opacity: 1;
      transition: background-color 0.3s; }
      .swiper-pagination-bullet-active {
        background-color: #F15980; }
      @media (max-width: 575px) {
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          margin: 0 3px; } }

.swiper-button-disabled {
  opacity: .6;
  pointer-events: none; }

.no-photo {
  background-image: url("../images/placeholder.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.theme-counter {
  cursor: pointer;
  display: grid;
  grid-template-columns: 52px 1fr 52px;
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  border-radius: 11px; }
  @media (max-width: 1919px) {
    .theme-counter {
      grid-template-columns: calc(0.02708 * 100vw + 0px) 1fr calc(0.02708 * 100vw + 0px);
      border-radius: calc(0.00573 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .theme-counter {
      grid-template-columns: 40px 1fr 40px;
      border-radius: 5px; } }
  .theme-counter__minus, .theme-counter__plus {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .theme-counter__minus svg, .theme-counter__plus svg {
      width: 13px;
      height: 13px;
      fill: #000;
      transition: fill 0.3s; }
      @media (max-width: 1919px) {
        .theme-counter__minus svg, .theme-counter__plus svg {
          width: calc(0.00677 * 100vw + 0px);
          height: calc(0.00677 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .theme-counter__minus svg, .theme-counter__plus svg {
          width: 10px;
          height: 10px; } }
    .theme-counter__minus:hover svg, .theme-counter__plus:hover svg {
      fill: #E64569; }
  .theme-counter__plus::after {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1px;
    height: calc(100% - 28px);
    margin: 14px 0;
    background-color: #AFAFAF; }
    @media (max-width: 1919px) {
      .theme-counter__plus::after {
        margin: calc(0.00729 * 100vw + 0px) 0;
        height: calc(0.01979 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-counter__plus::after {
        margin: 8px 0;
        height: 24px; } }
  .theme-counter__minus::after {
    content: '';
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 1px;
    height: calc(100% - 28px);
    margin: 14px 0;
    background-color: #AFAFAF; }
    @media (max-width: 1919px) {
      .theme-counter__minus::after {
        margin: calc(0.00729 * 100vw + 0px) 0;
        height: calc(0.01979 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-counter__minus::after {
        margin: 8px 0;
        height: 24px; } }
  .theme-counter__field {
    border: none;
    text-align: center;
    font-family: 'PT Sans';
    font-weight: 400;
    font-size: 20px;
    line-height: 125%; }
    @media (max-width: 1919px) {
      .theme-counter__field {
        font-size: calc(0.01042 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-counter__field {
        font-size: 14px; } }

@media (max-width: 575px) {
  .swal2-title {
    padding: 10px 20px 0;
    font-size: 22px; } }

@media (max-width: 575px) {
  .swal2-html-container {
    font-size: 16px; } }

@media (max-width: 575px) {
  .swal2-actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin: 0;
    padding: 10px 15px 0; }
    .swal2-actions .btn--swal {
      width: 100%;
      margin: 0; } }

@media (max-width: 575px) {
  .swal2-icon {
    width: 50px;
    height: 50px; }
    .swal2-icon .swal2-icon-content {
      font-size: 30px; } }

.dropdown-1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  max-height: 0px;
  overflow: hidden;
  transition: all .3s ease;
  top: 100%;
  z-index: 3;
  box-shadow: 1.5px 1.5px 9px 0px #00000029;
  border-radius: 0 0 12px 12px; }
  @media (max-width: 1919px) {
    .dropdown-1 {
      border-radius: 0 0 calc(0.00625 * 100vw + 0px) calc(0.00625 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .dropdown-1 {
      border-radius: 0 0 5px 5px; } }
  .dropdown-1__item {
    width: max-content;
    padding: 20px; }
    @media (max-width: 1919px) {
      .dropdown-1__item {
        padding: calc(0.01042 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .dropdown-1__item {
        padding: 10px; } }
    .dropdown-1__item__title {
      transition: all .3s ease;
      cursor: pointer;
      width: 100%; }
      .dropdown-1__item__title:hover {
        color: #FF1493; }
        .dropdown-1__item__title:hover span {
          color: #FF1493; }
      .dropdown-1__item__title span {
        font-size: 21px;
        line-height: 125%;
        color: #000;
        font-family: 'PT Sans';
        transition: all .3s ease; }
        @media (max-width: 1919px) {
          .dropdown-1__item__title span {
            font-size: calc(0.01094 * 100vw + 0px); } }
        @media (max-width: 991px) {
          .dropdown-1__item__title span {
            font-size: 12px; } }

.theme-form {
  position: relative; }

.theme-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffe6;
  z-index: 5; }
  .theme-loader img {
    width: 5%;
    height: 20%; }

.theme-input {
  display: block;
  position: relative;
  border: 1px solid #838383;
  border-radius: 11px;
  background-color: #fff;
  width: 100%;
  transition-property: border-color;
  transition-duration: 0.3s;
  cursor: text; }
  @media (max-width: 1919px) {
    .theme-input {
      border-radius: calc(0.00573 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .theme-input {
      border-radius: 5px; } }
  .theme-input:not(:last-child) {
    margin: 0 0 14px; }
    @media (max-width: 1919px) {
      .theme-input:not(:last-child) {
        margin: 0 0 calc(0.00729 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-input:not(:last-child) {
        margin: 0 0 8px; } }
  .theme-input.error {
    border-color: #ff2828; }
    .theme-input.error .theme-input__placeholder {
      color: #ff2828; }
  .theme-input__field {
    border: none;
    font-family: 'PT Sans';
    font-size: 21px;
    line-height: 140%;
    color: #000;
    width: 100%;
    background-color: transparent;
    position: relative;
    z-index: 1;
    padding: 14px 29px; }
    @media (max-width: 1919px) {
      .theme-input__field {
        font-size: calc(0.01094 * 100vw + 0px);
        padding: calc(0.00729 * 100vw + 0px) calc(0.0151 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-input__field {
        font-size: 14px;
        padding: 10px; } }
    .theme-input__field::placeholder {
      opacity: 0; }
    .theme-input__field:disabled {
      background-color: #E2E2E2;
      color: #6D6D6D;
      border-radius: 11px; }
      @media (max-width: 1919px) {
        .theme-input__field:disabled {
          border-radius: calc(0.00573 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .theme-input__field:disabled {
          border-radius: 5px; } }
    .theme-input__field:focus + span,
    .theme-input__field:not(:placeholder-shown) + span {
      top: 6px;
      font-size: 12px;
      line-height: 14px; }
      @media (max-width: 1919px) {
        .theme-input__field:focus + span,
        .theme-input__field:not(:placeholder-shown) + span {
          top: calc(0.00313 * 100vw + 0px);
          font-size: calc(0.00625 * 100vw + 0px);
          line-height: calc(0.00729 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .theme-input__field:focus + span,
        .theme-input__field:not(:placeholder-shown) + span {
          top: 0px;
          left: 10px;
          font-size: 11px;
          line-height: 125%; } }
  .theme-input__placeholder {
    position: absolute;
    display: block;
    font-family: 'PT Sans';
    font-size: 21px;
    line-height: 140%;
    color: #878787;
    left: 29px;
    top: calc(50% - 15px);
    transition-property: font-size, top, color;
    transition-duration: 0.3s; }
    @media (max-width: 1919px) {
      .theme-input__placeholder {
        top: calc(50% - 12px);
        font-size: calc(0.01094 * 100vw + 0px);
        left: calc(0.0151 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-input__placeholder {
        font-size: 14px;
        line-height: 22px;
        left: 10px; } }
  .theme-input__password-toggle {
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    right: 15px;
    top: calc(50% - 12px);
    fill: #999999 !important;
    stroke: none !important;
    cursor: pointer;
    transition: fill 0.3s;
    z-index: 3; }
    .theme-input__password-toggle.active {
      fill: #E64569; }

.theme-textarea__field {
  resize: none;
  height: 150px; }

.theme-textarea__placeholder {
  top: 15px; }

.theme-toggle {
  user-select: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: 65px 1fr;
  align-items: center; }
  .theme-toggle:not(:last-child) {
    margin: 0 0 10px; }
  .theme-toggle:hover .theme-toggle__name {
    color: #E64569; }
  .theme-toggle__input {
    display: none; }
    .theme-toggle__input:checked + div {
      background-color: #282828;
      border-color: #282828; }
      .theme-toggle__input:checked + div::before {
        right: 3px;
        border-color: #282828; }
  .theme-toggle__fake {
    position: relative;
    width: 50px;
    height: 28px;
    background-color: #E3E3E3;
    border: 1px solid #D0D0D0;
    transition: 0.3s; }
    .theme-toggle__fake::before {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: calc(100% - 4px);
      background-color: #FFFFFF;
      border: 1px solid #D0D0D0;
      top: 2px;
      right: calc(100% - 50% - 3px);
      transition: 0.3s; }
  .theme-toggle__name {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    transition: color 0.3s; }

.choice-block {
  cursor: pointer;
  user-select: none; }
  .choice-block__field {
    display: none; }
    .choice-block__field:checked + .choice-block__front {
      border-color: #D0D0D0;
      background-color: transparent; }
      .choice-block__field:checked + .choice-block__front .choice-block__fake {
        background-color: #282828;
        border-color: #282828; }
        .choice-block__field:checked + .choice-block__front .choice-block__fake svg {
          fill: #FFFFFF; }
  .choice-block__front {
    background-color: #F8F8F8;
    border: 1px solid transparent;
    padding: 20px 18px;
    transition: 0.3s;
    width: 100%;
    height: 100%; }
  .choice-block__fake {
    width: 22px;
    height: 22px;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s; }
    .choice-block__fake svg {
      width: 12px;
      height: 9px;
      fill: transparent;
      transition: fill 0.3s; }
  .choice-block__title {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin: 12px 0 0; }
  .choice-block__desc {
    font-size: 14px;
    line-height: 18px;
    color: #7D7D7D;
    margin: 8px 0 0; }
  .choice-block__link {
    padding: 10px 0 0;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline; }
    .choice-block__link:hover {
      color: #E64569; }

.theme-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none; }
  .theme-checkbox__field {
    display: none; }
    .theme-checkbox__field:checked + div svg {
      opacity: 1; }
  .theme-checkbox__fake {
    order: 2;
    width: 18px;
    height: 18px;
    margin-left: 8px;
    background: #FFFFFF;
    transition: 0.3s; }
    @media (max-width: 1919px) {
      .theme-checkbox__fake {
        width: calc(0.00937 * 100vw + 0px);
        height: calc(0.00937 * 100vw + 0px);
        margin-left: calc(0.00417 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-checkbox__fake {
        width: 12px;
        height: 12px;
        margin-left: 5px; } }
    .theme-checkbox__fake svg {
      width: 12px;
      height: 12px;
      stroke: #000;
      opacity: 0;
      transition: opacity 0.3s; }
      @media (max-width: 1919px) {
        .theme-checkbox__fake svg {
          width: calc(0.00625 * 100vw + 0px);
          height: calc(0.00625 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .theme-checkbox__fake svg {
          width: 10px;
          height: 10px; } }
  .theme-checkbox__name {
    order: 1;
    font-size: 14px;
    line-height: 18px;
    color: #282828; }
    @media (max-width: 1919px) {
      .theme-checkbox__name {
        font-size: calc(0.00729 * 100vw + 0px);
        line-height: calc(0.00937 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .theme-checkbox__name {
        font-size: 12px;
        line-height: 125%; } }

.theme-submit {
  width: 100%;
  height: 62px; }
  @media (max-width: 1919px) {
    .theme-submit {
      height: calc(0.03229 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .theme-submit {
      height: 35px; } }

.g-recaptcha {
  overflow: hidden;
  max-width: 100%; }
  .g-recaptcha div {
    max-width: 100%; }
  .g-recaptcha:not(:last-child) {
    margin: 0 0 14px; }

.theme-policy {
  font-family: 'PT Sans';
  line-height: 115%;
  font-size: 14px;
  color: #000;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1919px) {
    .theme-policy {
      font-size: calc(0.00729 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .theme-policy {
      font-size: 11px; } }
  .theme-policy a {
    text-decoration: underline;
    color: #FF1493; }

.noUi-horizontal {
  height: 4px; }

.noUi-target {
  background: #999999;
  border: none;
  box-shadow: none;
  margin: 0 10px; }

.noUi-connect {
  background-color: #282828; }

.noUi-horizontal .noUi-handle {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border: none;
  background: #282828;
  border-radius: 50%;
  box-shadow: none;
  right: -7px;
  top: -6px; }
  .noUi-horizontal .noUi-handle:after, .noUi-horizontal .noUi-handle:before {
    display: none; }

.noUi-touch-area {
  display: none; }

.radio-block {
  cursor: pointer; }
  .radio-block:not(:last-child) {
    margin-right: 10px; }
  .radio-block__field {
    display: none; }
    .radio-block__field:checked + span {
      border-color: #E64569; }
  .radio-block__name {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    padding: 5px 15px;
    transition: border-color 0.3s; }
    .radio-block__name:hover {
      border-color: #282828; }

.theme-select {
  position: relative; }
  .theme-select__list {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 15px);
    left: -1px;
    min-width: calc(100% + 2px);
    border: 1px solid #c9ced8;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    box-shadow: 4px 6px 8px 0px rgba(35, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    transition: 0.3s;
    z-index: 3; }
    .theme-select__list.opened {
      visibility: visible;
      opacity: 1;
      top: calc(100% + 5px); }
    .theme-select__list label {
      cursor: pointer;
      padding: 3px 10px;
      transition: background-color 0.3s; }
      .theme-select__list label:hover {
        background-color: #eaeaea; }
      .theme-select__list label input {
        display: none; }
      .theme-select__list label span {
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        white-space: nowrap; }

.theme-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .theme-success svg {
    width: 36px;
    height: 28px;
    fill: #14A356;
    margin: 0 0 25px; }
  .theme-success span {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #333333; }
    @media (max-width: 991px) {
      .theme-success span {
        font-size: 24px;
        line-height: 28px; } }

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: scroll; }

.modal__container {
  background-color: #fff;
  padding: 50px 60px;
  max-height: 95vh;
  overflow: auto;
  max-width: 600px;
  border-radius: 15px; }
  .modal__container.modal-stock {
    max-width: 1785px;
    width: 100%;
    padding: 44px 80px 59px 59px;
    border-radius: 14px; }

.modal__content .theme-policy {
  text-align: center; }

.modal__title {
  margin-bottom: 30px; }
  @media (max-width: 1919px) {
    .modal__title {
      margin-bottom: calc(0.01562 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .modal__title {
      margin-bottom: 22px; } }
  .modal__title--center {
    text-align: center; }
  .modal__title p {
    font-family: 'Golos';
    font-weight: 600;
    font-size: 39px;
    line-height: 120%; }
    @media (max-width: 1919px) {
      .modal__title p {
        font-size: calc(0.02031 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .modal__title p {
        font-size: 18px; } }
    .modal__title p span {
      font-family: 'Golos';
      font-weight: 600;
      font-size: 39px;
      line-height: 120%;
      color: #0F20E5;
      padding: 0; }
      @media (max-width: 1919px) {
        .modal__title p span {
          font-size: calc(0.02031 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .modal__title p span {
          font-size: 18px; } }
  .modal__title span {
    font-family: 'Golos';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    padding: 0 45px;
    text-align: center; }
    @media (max-width: 1919px) {
      .modal__title span {
        font-size: calc(0.00833 * 100vw + 0px);
        padding: 0 calc(0.02344 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .modal__title span {
        font-size: 12px;
        padding: 22px; } }

.modal__map {
  display: grid;
  grid-template-columns: 1fr 550px;
  column-gap: 32px; }
  .modal__map__left {
    filter: drop-shadow(1.48px 1.48px 8.91px rgba(0, 0, 0, 0.16));
    border-radius: 14px;
    max-height: 600px;
    height: 78vh;
    background-color: #fdfdfd; }
  .modal__map__right {
    box-shadow: 1.46px 1.46px 8.77px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    padding: 20px 10px; }
    .modal__map__right__wrapper {
      max-height: 615px;
      overflow-x: auto;
      padding: 0 61px 0 38px; }
      .modal__map__right__wrapper::-webkit-scrollbar {
        width: 2px;
        /* ширина всей полосы прокрутки */ }
      .modal__map__right__wrapper::-webkit-scrollbar-thumb {
        background-color: #E64569;
        /* цвет бегунка */
        border-radius: 20px;
        /* округлось бегунка */
        border: 3px solid #E64569;
        /* отступ вокруг бегунка */ }
  .modal__map__address {
    display: flex;
    flex-direction: column;
    margin-bottom: 34px; }
    .modal__map__address__title {
      margin-bottom: 35px;
      cursor: pointer; }
      .modal__map__address__title:hover span {
        color: #0F20E5; }
      .modal__map__address__title span {
        font-family: 'Golos';
        font-weight: 500;
        font-size: 26px;
        line-height: 125%; }
    .modal__map__address__item {
      display: flex;
      align-items: start; }
      .modal__map__address__item__val {
        display: flex;
        flex-direction: column; }
        .modal__map__address__item__val span:not(:last-child) {
          margin-bottom: 10px; }
      .modal__map__address__item svg {
        height: 32px;
        width: 24px;
        fill: #E64569;
        margin-right: 15px; }
      .modal__map__address__item span {
        font-family: 'PT Sans';
        font-size: 21px;
        line-height: 140%; }
      .modal__map__address__item:not(:last-child) {
        margin-bottom: 13px; }
    .modal__map__address:not(:last-child) {
      border-bottom: 1px solid #CBCBCB;
      padding: 34px 0; }

.modal__subtitle {
  font-weight: normal;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin: 12px 0 0; }
  @media (max-width: 991px) {
    .modal__subtitle {
      font-size: 14px;
      line-height: 18px;
      margin: 6px 0 0; }
      .modal__subtitle br {
        display: none; } }

.modal__close {
  position: absolute;
  cursor: pointer;
  border: none;
  font-size: 30px;
  line-height: 30px;
  background-color: transparent;
  top: 0;
  right: 0;
  padding: 8px 18px;
  color: #000;
  transition: color 0.3s; }
  .modal__close:hover {
    color: #E64569; }
  @media (max-width: 991px) {
    .modal__close {
      padding: 0 10px; } }

@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from { }
  to { } }

@keyframes mmslideOut {
  from { }
  to { } }

.micromodal-slide {
  display: none; }

.micromodal-slide.is-open {
  display: block; }

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform; }

.header {
  font-family: 'Golos'; }
  @media (max-width: 991px) {
    .header {
      position: sticky;
      top: 0;
      z-index: 25; } }
  .header-top {
    background-color: #000; }
    .header-top__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px; }
      @media (max-width: 1919px) {
        .header-top__wrapper {
          height: calc(0.03125 * 100vw + 0px); } }
    .header-top__title {
      height: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 21px;
      transition: opacity .3s ease; }
      @media (max-width: 1919px) {
        .header-top__title {
          font-size: calc(0.01094 * 100vw + 0px); } }
      .header-top__title.dashed {
        border-bottom: 1px dashed #fff;
        cursor: pointer; }
        .header-top__title.dashed:hover {
          border-color: transparent; }
    .header-top__left {
      display: flex;
      height: 100%;
      margin-bottom: .3%;
      margin-left: .4%; }
      .header-top__left__item {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        margin-right: 60px; }
        @media (max-width: 1919px) {
          .header-top__left__item {
            margin-right: calc(0.03125 * 100vw + 0px); } }
        .header-top__left__item svg {
          width: 12px;
          margin-left: 7px;
          margin-bottom: 1px;
          height: 7px;
          fill: #000;
          stroke: #fff;
          transition: all .3s ease; }
          @media (max-width: 1919px) {
            .header-top__left__item svg {
              width: calc(0.00625 * 100vw + 0px);
              margin-left: calc(0.00365 * 100vw + 0px);
              margin-bottom: calc(0.00052 * 100vw + 0px);
              height: calc(0.00365 * 100vw + 0px); } }
        .header-top__left__item__dropdown {
          position: absolute;
          z-index: 1;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          max-height: 0;
          overflow: hidden; }
        .header-top__left__item:hover .header-top__title {
          opacity: .5; }
        .header-top__left__item:hover svg {
          opacity: .5;
          transform: scaleY(-1); }
        .header-top__left__item:hover .dropdown-1 {
          max-height: 60vh; }
    .header-top__right {
      display: flex;
      align-items: center; }
      .header-top__right__item {
        padding: 3px 23.8px;
        border-left: 1px solid #fff;
        height: 2vw;
        display: flex;
        align-items: center; }
        @media (max-width: 1919px) {
          .header-top__right__item {
            padding: calc(0.00156 * 100vw + 0px) calc(0.0124 * 100vw + 0px); } }
        .header-top__right__item:last-child {
          padding-right: 0; }
        .header-top__right__item-phone {
          font-weight: 500;
          color: #fff;
          font-size: 21px;
          line-height: 26.25px;
          margin-right: 5px;
          transition: all .3s ease; }
          @media (max-width: 1919px) {
            .header-top__right__item-phone {
              font-size: calc(0.01094 * 100vw + 0px);
              line-height: calc(0.01367 * 100vw + 0px);
              margin-right: calc(0.0026 * 100vw + 0px); } }
          .header-top__right__item-phone:hover {
            color: #FF1493; }
        .header-top__right__item-work {
          color: #CCC;
          font-size: 21px;
          line-height: 26.25px; }
          @media (max-width: 1919px) {
            .header-top__right__item-work {
              font-size: calc(0.01094 * 100vw + 0px); } }
      .header-top__right__vk:hover svg {
        fill: #FF1493; }
      .header-top__right__vk svg {
        width: 30px;
        height: 30px;
        fill: #fff;
        transition: all .3s ease; }
        @media (max-width: 1919px) {
          .header-top__right__vk svg {
            width: calc(0.01562 * 100vw + 0px);
            height: calc(0.01562 * 100vw + 0px); } }
  .header .debug {
    margin-top: 0px; }
    @media (max-width: 1919px) {
      .header .debug {
        margin-top: calc(0 * 100vw + 0px); } }
  .header-bottom {
    box-shadow: 0px 0px 24px 0px #00000029;
    background-color: #fff; }
    @media (max-width: 1919px) {
      .header-bottom {
        box-shadow: 0px 0px calc(0.0125 * 100vw + 0px) 0px #00000029; } }
    .header-bottom__wrapper {
      display: flex;
      justify-content: space-between;
      height: 102px;
      align-items: center; }
      @media (max-width: 1919px) {
        .header-bottom__wrapper {
          height: calc(0.05312 * 100vw + 0px); } }
    .header-bottom__logo {
      margin-right: 37.5px; }
      @media (max-width: 1919px) {
        .header-bottom__logo {
          margin-right: calc(0.01953 * 100vw + 0px); } }
      .header-bottom__logo img {
        width: 153px;
        height: 78px;
        object-fit: cover; }
        @media (max-width: 1919px) {
          .header-bottom__logo img {
            width: calc(0.07969 * 100vw + 0px);
            height: calc(0.04063 * 100vw + 0px); } }
    .header-bottom__catalog-btn {
      margin-right: 37px; }
      @media (max-width: 1919px) {
        .header-bottom__catalog-btn {
          margin-right: calc(0.01927 * 100vw + 0px); } }
      .header-bottom__catalog-btn .catalog-btn {
        display: flex;
        background-color: #000;
        align-items: center;
        padding: 12px 9px 12px 12px;
        border-radius: 12px;
        cursor: pointer; }
        @media (max-width: 1919px) {
          .header-bottom__catalog-btn .catalog-btn {
            padding: calc(0.00625 * 100vw + 0px) calc(0.00469 * 100vw + 0px) calc(0.00625 * 100vw + 0px) calc(0.00625 * 100vw + 0px);
            border-radius: calc(0.00625 * 100vw + 0px); } }
        .header-bottom__catalog-btn .catalog-btn:hover span:nth-child(1) {
          transform: rotate(90deg); }
        .header-bottom__catalog-btn .catalog-btn:hover span:nth-child(2) {
          transform: rotate(-90deg); }
        .header-bottom__catalog-btn .catalog-btn:hover span:nth-child(3) {
          transform: rotate(-90deg); }
        .header-bottom__catalog-btn .catalog-btn:hover span:nth-child(4) {
          transform: rotate(90deg); }
        .header-bottom__catalog-btn .catalog-btn.opened svg {
          transform: scaleY(-1); }
        .header-bottom__catalog-btn .catalog-btn__icon {
          display: grid;
          grid-template-columns: 13.5px 13.5px;
          grid-template-rows: 13.5px 13.5px;
          column-gap: 3px;
          row-gap: 3px; }
          @media (max-width: 1919px) {
            .header-bottom__catalog-btn .catalog-btn__icon {
              grid-template-columns: calc(0.00703 * 100vw + 0px) calc(0.00703 * 100vw + 0px);
              grid-template-rows: calc(0.00703 * 100vw + 0px) calc(0.00703 * 100vw + 0px);
              column-gap: calc(0.00156 * 100vw + 0px);
              row-gap: calc(0.00156 * 100vw + 0px); } }
          .header-bottom__catalog-btn .catalog-btn__icon span {
            border: 1.5px solid #FFFFFF;
            border-radius: 3px;
            transition: all .3s ease; }
            @media (max-width: 1919px) {
              .header-bottom__catalog-btn .catalog-btn__icon span {
                border-radius: calc(0.00156 * 100vw + 0px); } }
        .header-bottom__catalog-btn .catalog-btn svg {
          margin-left: 6px;
          width: 9px;
          height: 9px;
          stroke: #fff;
          transition: all .3s ease; }
          @media (max-width: 1919px) {
            .header-bottom__catalog-btn .catalog-btn svg {
              margin-left: calc(0.00313 * 100vw + 0px);
              width: calc(0.00469 * 100vw + 0px);
              height: calc(0.00469 * 100vw + 0px); } }
    .header-bottom__menu {
      display: flex;
      justify-content: space-between;
      height: 100%;
      margin-right: 95px; }
      @media (max-width: 1919px) {
        .header-bottom__menu {
          margin-right: calc(0.04948 * 100vw + 0px); } }
      .header-bottom__menu-item {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        position: relative; }
        .header-bottom__menu-item:not(:last-child) {
          margin-right: 60px; }
          @media (max-width: 1919px) {
            .header-bottom__menu-item:not(:last-child) {
              margin-right: calc(0.03125 * 100vw + 0px); } }
        .header-bottom__menu-item__title {
          font-size: 22.5px;
          line-height: 28.13px;
          color: #000;
          margin-right: 7px; }
          @media (max-width: 1919px) {
            .header-bottom__menu-item__title {
              font-size: calc(0.01172 * 100vw + 0px);
              line-height: calc(0.01465 * 100vw + 0px);
              margin-right: calc(0.00365 * 100vw + 0px); } }
        .header-bottom__menu-item svg {
          width: 12px;
          height: 8px;
          fill: transparent;
          stroke: #000;
          transition: all .3s ease; }
          @media (max-width: 1919px) {
            .header-bottom__menu-item svg {
              width: calc(0.00625 * 100vw + 0px);
              height: calc(0.00417 * 100vw + 0px); } }
        .header-bottom__menu-item:hover .header-bottom__menu-item__title {
          text-decoration: underline; }
        .header-bottom__menu-item:hover svg {
          transform: scaleY(-1); }
        .header-bottom__menu-item:hover .dropdown-1 {
          max-height: 60vh; }
    .header-bottom__search {
      max-width: 851px;
      width: 100%;
      margin-right: 58px;
      margin-bottom: 3px;
      height: 63px; }
      @media (max-width: 1919px) {
        .header-bottom__search {
          max-width: calc(0.44323 * 100vw + 0px);
          margin-right: calc(0.03021 * 100vw + 0px);
          margin-bottom: calc(0.00156 * 100vw + 0px);
          height: calc(0.03281 * 100vw + 0px); } }
    .header-bottom__action-buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px; }
      @media (max-width: 1919px) {
        .header-bottom__action-buttons {
          margin-bottom: calc(0.00156 * 100vw + 0px); } }
      .header-bottom__action-buttons a {
        position: relative;
        cursor: pointer; }
        .header-bottom__action-buttons a:hover .fill {
          fill: #FF1493; }
        .header-bottom__action-buttons a:hover .stroke {
          stroke: #FF1493; }
        .header-bottom__action-buttons a:not(:last-child) {
          margin-right: 44px; }
          @media (max-width: 1919px) {
            .header-bottom__action-buttons a:not(:last-child) {
              margin-right: calc(0.02292 * 100vw + 0px); } }
        .header-bottom__action-buttons a span {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          text-align: center;
          border-radius: 50%;
          color: #fff;
          font-family: 'Golos';
          right: -8px;
          top: -8px;
          background-color: #E64569;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 125%; }
          .header-bottom__action-buttons a span:empty {
            display: none; }
          @media (max-width: 1919px) {
            .header-bottom__action-buttons a span {
              width: calc(0.01042 * 100vw + 0px);
              height: calc(0.01042 * 100vw + 0px);
              right: calc(-0.00417 * 100vw + 0px);
              top: calc(-0.00417 * 100vw + 0px);
              font-size: calc(0.00729 * 100vw + 0px); } }
        .header-bottom__action-buttons a svg {
          width: 29px;
          height: 29px;
          transition: all .3s ease; }
          @media (max-width: 1919px) {
            .header-bottom__action-buttons a svg {
              width: calc(0.0151 * 100vw + 0px);
              height: calc(0.0151 * 100vw + 0px); } }
          .header-bottom__action-buttons a svg.fill {
            fill: #000;
            stroke: transparent; }
          .header-bottom__action-buttons a svg.stroke {
            fill: transparent;
            stroke: #000; }
    .header-bottom-2 {
      margin-top: 0px;
      height: 0px;
      box-shadow: none;
      transition: all .3s ease;
      overflow: hidden; }
      .header-bottom-2 .header-bottom__search {
        max-width: 750px; }
        @media (max-width: 1919px) {
          .header-bottom-2 .header-bottom__search {
            max-width: calc(0.39062 * 100vw + 0px); } }
  .header-fixed {
    position: static;
    width: 100%;
    background-color: #fff;
    top: 0;
    max-height: clamp(114px, calc(0.08906 * 100vw), 171px);
    transition: height .3s ease;
    z-index: 20;
    box-shadow: 0 0 24px 0 #00000029; }
    @media (max-width: 1919px) {
      .header-fixed {
        box-shadow: 0 0 calc(0.0125 * 100vw + 0px) 0 #00000029; } }
    .header-fixed.opened .debug_work {
      display: none; }
    .header-fixed.opened .header-bottom-2 {
      height: 102px;
      overflow: visible; }
      @media (max-width: 1919px) {
        .header-fixed.opened .header-bottom-2 {
          height: calc(0.05312 * 100vw + 0px); } }
    .header-fixed__debug-height {
      max-height: clamp(180.66667px, calc(0.14115 * 100vw), 271px); }
  .header__margin {
    margin-bottom: 60px; }
    @media (max-width: 1919px) {
      .header__margin {
        margin-bottom: calc(0.03125 * 100vw + 0px); } }
  .header__margin_debug {
    margin-bottom: 0px !important; }
    @media (max-width: 1919px) {
      .header__margin_debug {
        margin-bottom: calc(0 * 100vw + 0px) !important; } }
  .header .header-menu {
    display: flex;
    position: fixed;
    width: fit-content;
    top: 162px;
    z-index: 19;
    border-radius: 0 0 15px 15px;
    padding: 0 58px;
    overflow: hidden;
    max-height: 0px;
    transition: all .3s ease; }
    @media (max-width: 1919px) {
      .header .header-menu {
        top: calc(0.08438 * 100vw + 0px);
        border-radius: 0 0 calc(0.00781 * 100vw + 0px) calc(0.00781 * 100vw + 0px);
        padding: 0 calc(0.03021 * 100vw + 0px);
        overflow: hidden; } }
    .header .header-menu.opened {
      max-height: 100vw; }
    .header .header-menu__wrapper {
      display: flex;
      flex-direction: column;
      padding: 6px 33px;
      margin-bottom: 20px;
      width: 580px;
      background-color: #fff;
      box-shadow: 0 0 24px 0 #00000029;
      border-radius: 0px 0px 15px 15px;
      z-index: 16; }
      @media (max-width: 1919px) {
        .header .header-menu__wrapper {
          padding: calc(0.00313 * 100vw + 0px) calc(0.01719 * 100vw + 0px);
          width: calc(0.30208 * 100vw + 0px);
          margin-bottom: calc(0.01042 * 100vw + 0px);
          border-radius: 0px 0px calc(0.00781 * 100vw + 0px) calc(0.00781 * 100vw + 0px); } }
    .header .header-menu__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 27px; }
      @media (max-width: 1919px) {
        .header .header-menu__item {
          padding: calc(0.0125 * 100vw + 0px) calc(0.01406 * 100vw + 0px); } }
      .header .header-menu__item:hover span {
        color: #FF1493; }
      .header .header-menu__item span {
        font-family: 'Golos';
        font-weight: 500;
        font-size: 20px;
        line-height: 125%;
        transition: all .3s ease; }
        @media (max-width: 1919px) {
          .header .header-menu__item span {
            font-size: calc(0.0125 * 100vw + 0px); } }
      .header .header-menu__item svg {
        width: 12px;
        height: 12px;
        stroke: #000;
        fill: transparent;
        transform: rotate(-90deg); }
        @media (max-width: 1919px) {
          .header .header-menu__item svg {
            width: calc(0.00625 * 100vw + 0px);
            height: calc(0.00625 * 100vw + 0px); } }
      .header .header-menu__item:not(:last-child) {
        border-bottom: 1px solid #D9D9D9; }
    .header .header-menu__list {
      background-color: #fff;
      box-shadow: 1.5px 1.5px 9px rgba(0, 0, 0, 0.16);
      border-radius: 0 0 15px 15px;
      margin-left: 10px;
      padding: 50px 36px;
      width: 1205px;
      display: flex;
      justify-content: space-between;
      height: min-content;
      display: none; }
      @media (max-width: 1919px) {
        .header .header-menu__list {
          border-radius: 0 0 calc(0.00781 * 100vw + 0px) calc(0.00781 * 100vw + 0px);
          margin-left: calc(0.00521 * 100vw + 0px);
          padding: calc(0.02604 * 100vw + 0px) calc(0.01875 * 100vw + 0px);
          width: calc(0.6276 * 100vw + 0px);
          box-shadow: calc(0.00078 * 100vw + 0px) calc(0.00078 * 100vw + 0px) calc(0.00469 * 100vw + 0px) rgba(0, 0, 0, 0.16); } }
      .header .header-menu__list.opened {
        display: flex;
        overflow: auto; }
      .header .header-menu__list__left {
        display: flex;
        flex-direction: column; }
      .header .header-menu__list__right {
        display: flex;
        height: 84%; }
        .header .header-menu__list__right img {
          object-fit: contain;
          height: 337px; }
          @media (max-width: 1919px) {
            .header .header-menu__list__right img {
              height: calc(0.17552 * 100vw + 0px); } }
      .header .header-menu__list__title {
        margin-left: 30px;
        margin-bottom: 14px; }
        @media (max-width: 1919px) {
          .header .header-menu__list__title {
            margin-left: calc(0.01562 * 100vw + 0px);
            margin-bottom: calc(0.01771 * 100vw + 0px); } }
        .header .header-menu__list__title span {
          font-family: 'Golos';
          font-weight: 600;
          font-size: 30px;
          line-height: 120%; }
          @media (max-width: 1919px) {
            .header .header-menu__list__title span {
              font-size: calc(0.01562 * 100vw + 0px); } }
      .header .header-menu__list__item {
        padding: clamp(3.33333px, calc(0.0026 * 100vw), 5px) clamp(20px, calc(0.01562 * 100vw), 30px);
        border-radius: 30px;
        border: 1px solid transparent;
        transition: all .3s ease; }
        @media (max-width: 1919px) {
          .header .header-menu__list__item {
            border-radius: calc(0.01562 * 100vw + 0px); } }
        .header .header-menu__list__item:hover {
          border-color: #FF1493; }
          .header .header-menu__list__item:hover span {
            color: #FF1493; }
        .header .header-menu__list__item:not(:last-child) {
          margin-bottom: clamp(3.33333px, calc(0.0026 * 100vw), 5px); }
        .header .header-menu__list__item span {
          font-family: 'Golos';
          font-weight: 500;
          font-size: 19px;
          line-height: 125%;
          border: 1px solid transparent;
          transition: all .3s ease; }
          @media (max-width: 1919px) {
            .header .header-menu__list__item span {
              font-size: calc(0.0125 * 100vw + 0px); } }
    .header .header-menu__menu-wrapper {
      box-shadow: 1.5px 1.5px 9px 0 #00000029; }
      @media (max-width: 1919px) {
        .header .header-menu__menu-wrapper {
          box-shadow: calc(0.00078 * 100vw + 0px) calc(0.00078 * 100vw + 0px) calc(0.00469 * 100vw + 0px) 0 #00000029; } }
  .header .toggle-on {
    animation-name: open;
    animation-duration: .4s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 0s; }
  .header .toggle-off {
    animation-name: close;
    animation-duration: .4s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 0s; }

@keyframes open {
  0% {
    height: 0;
    overflow: hidden; }
  100% {
    height: clamp(112.66667px, calc(0.08802 * 100vw), 169px);
    overflow: visible; } }

@keyframes close {
  0% {
    height: clamp(112.66667px, calc(0.08802 * 100vw), 169px);
    overflow: hidden; }
  100% {
    height: 0;
    overflow: hidden; } }

.debug_work {
  font-family: Golos;
  font-style: normal;
  background-image: url("/static/images/works.png");
  background-size: 100%;
  background-size: cover; }
  .debug_work__container {
    padding: 18px 0px 23px; }
    @media (max-width: 1919px) {
      .debug_work__container {
        padding: calc(0.00937 * 100vw + 0px) 0px calc(0.01198 * 100vw + 0px); } }
  .debug_work__title {
    color: #000;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 8px; }
    @media (max-width: 1919px) {
      .debug_work__title {
        font-size: calc(0.01562 * 100vw + 0px);
        margin-bottom: calc(0.00417 * 100vw + 0px); } }
  @media (max-width: 1919px) and (max-width: 991px) {
    .debug_work__title {
      margin-bottom: 5px;
      font-size: 15px; } }
  .debug_work__text {
    color: #000;
    font-size: calc(0.01042 * 100vw + 0px);
    font-weight: 500; }
    @media (max-width: 991px) {
      .debug_work__text {
        font-size: 10px; } }

.header-mobile {
  display: flex;
  flex-direction: column;
  background: #fff; }
  .header-mobile__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    height: 35px;
    width: 100%;
    padding: 0 15px; }
    .header-mobile__top__logo img {
      height: 100%;
      width: 72px;
      object-fit: cover; }
    .header-mobile__top__phone {
      border-left: 1px solid #fff;
      margin: 7px 0 8px;
      padding: 0 5px 0 9px; }
      .header-mobile__top__phone svg {
        stroke: none;
        fill: #fff;
        width: 16px;
        aspect-ratio: 1/1; }
  .header-mobile__bottom {
    position: relative;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px; }
    .header-mobile__bottom__actions a {
      position: relative; }
      .header-mobile__bottom__actions a svg {
        width: 17px;
        aspect-ratio: 1/1; }
        .header-mobile__bottom__actions a svg.stroke {
          stroke: #000;
          fill: transparent; }
        .header-mobile__bottom__actions a svg.fill {
          stroke: transparent;
          fill: #000; }
      .header-mobile__bottom__actions a span {
        width: 15px;
        aspect-ratio: 1/1;
        display: flex;
        background-color: #e64569;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        line-height: 125%;
        color: #fff;
        position: absolute;
        top: -5px;
        right: -5px; }
        .header-mobile__bottom__actions a span:empty {
          display: none; }
      .header-mobile__bottom__actions a:not(:last-child) {
        margin-right: 21px; }
  .header-mobile-search svg {
    stroke: #000;
    fill: #fff;
    width: 17px;
    height: 17px;
    margin-right: 21px; }
  .header-mobile-search-show {
    transition: .3s ease;
    height: clamp(37px, calc(0.02969 * 100vw), 57px);
    z-index: 5;
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    top: 40px; }

.header-burger {
  position: relative;
  width: 20px;
  height: 16px; }
  .header-burger span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: 0.3s;
    transition-property: top, bottom, opacity, transform; }
    .header-burger span:nth-child(1) {
      top: 0; }
    .header-burger span:nth-child(2) {
      top: 0;
      bottom: 0;
      margin: auto; }
    .header-burger span:nth-child(3) {
      bottom: 0; }
  .header-burger.opened span:nth-child(1) {
    top: calc(50% - 1px);
    transform: rotate(45deg); }
  .header-burger.opened span:nth-child(2) {
    opacity: 0; }
  .header-burger.opened span:nth-child(3) {
    bottom: calc(50% - 1px);
    transform: rotate(-45deg); }

.mobile-menu {
  position: fixed;
  z-index: 20;
  top: -125%;
  width: 90%;
  margin: 0 5%;
  padding: 16px 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  transition: all .3s ease-out;
  border-radius: 0 0 3px 3px;
  box-shadow: 0.32px 0.32px 1.92px rgba(0, 0, 0, 0.16); }
  .mobile-menu.opened {
    top: auto;
    padding-top: 0px; }
  .mobile-menu .mobile-actions {
    display: grid;
    grid-template-columns: repeat(4, 1fr); }
    @media (max-width: 575px) {
      .mobile-menu .mobile-actions {
        grid-template-columns: repeat(2, 1fr); } }
  .mobile-menu .header-button {
    flex-direction: row;
    padding: 20px; }
    .mobile-menu .header-button:not(:last-child) {
      margin: 0; }
    .mobile-menu .header-button svg {
      margin: 0 12px 0 0; }
    @media (max-width: 1199px) {
      .mobile-menu .header-button:not(:last-child) {
        border-right: 1px solid #D0D0D0; } }
    @media (max-width: 575px) {
      .mobile-menu .header-button:not(:last-child) {
        border-right: none; }
      .mobile-menu .header-button:nth-child(odd) {
        border-right: 1px solid #D0D0D0; }
      .mobile-menu .header-button:nth-child(1), .mobile-menu .header-button:nth-child(2) {
        border-bottom: 1px solid #D0D0D0; } }
  .mobile-menu .mobile-nav__list {
    padding: 11px 15px 0;
    display: none;
    max-height: 50vh;
    overflow: scroll; }
    .mobile-menu .mobile-nav__list.active {
      display: block; }
    .mobile-menu .mobile-nav__list li:not(:last-child) {
      border-bottom: 1px solid #D9D9D9; }
    .mobile-menu .mobile-nav__list li.back {
      border: none;
      border-radius: 5px;
      background-color: #f4f4f4;
      width: fit-content; }
      .mobile-menu .mobile-nav__list li.back p {
        justify-content: start; }
      .mobile-menu .mobile-nav__list li.back svg {
        fill: #000;
        width: 12px;
        transform: none;
        margin-right: 12px; }
    .mobile-menu .mobile-nav__list li p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 15px 8px 11px; }
    .mobile-menu .mobile-nav__list li svg {
      fill: transparent;
      stroke: #000;
      width: 8px;
      aspect-ratio: 1/1;
      transform: rotate(-90deg);
      margin-right: 10px; }
    .mobile-menu .mobile-nav__list li span {
      font-family: 'Golos';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 125%; }
  .mobile-menu .mobile-phone {
    display: flex;
    flex-direction: column;
    padding: 0 14px;
    margin-top: 28px;
    margin-bottom: 15px; }
    .mobile-menu .mobile-phone a {
      display: flex;
      align-items: center;
      margin-bottom: 4px; }
      .mobile-menu .mobile-phone a svg {
        width: 16px;
        height: 16px;
        fill: #000;
        margin-right: 2px; }
      .mobile-menu .mobile-phone a span {
        font-family: 'Golos';
        font-size: 18px;
        line-height: 125%;
        opacity: 1; }
    .mobile-menu .mobile-phone span {
      font-family: 'Golos';
      font-size: 12px;
      line-height: 125%;
      opacity: .8; }
  .mobile-menu .mobile-menu-btn {
    height: 34px;
    margin: 0 15px; }
  .mobile-menu .mobile-lead {
    padding: 15px 15px 20px;
    background-color: #F8F8F8; }
    .mobile-menu .mobile-lead .header-lead {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px; }
      .mobile-menu .mobile-lead .header-lead__button {
        width: 100%;
        height: 45px; }
    .mobile-menu .mobile-lead .lead-phone {
      margin-right: 0px; }
      .mobile-menu .mobile-lead .lead-phone span {
        font-size: 13px; }
  .mobile-menu .mobile-search {
    padding: 0 15px; }
    .mobile-menu .mobile-search .search-line {
      width: 100%; }

.js-hide {
  height: 0px; }

.footer {
  background-color: #000;
  overflow: hidden; }
  .footer__wrapper {
    display: grid;
    grid-template-columns: 380px 226px 225px 379px 1fr;
    column-gap: 80px;
    padding-top: 45px; }
    @media (max-width: 1919px) {
      .footer__wrapper {
        grid-template-columns: calc(0.19792 * 100vw + 0px) calc(0.11771 * 100vw + 0px) calc(0.11719 * 100vw + 0px) calc(0.1974 * 100vw + 0px) 1fr;
        column-gap: calc(0.04167 * 100vw + 0px);
        padding-top: calc(0.02344 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__wrapper {
        grid-template-columns: 1fr;
        padding-top: 28px;
        row-gap: 44px; } }
  .footer__col {
    display: flex;
    flex-direction: column; }
    .footer__col__wrap {
      padding-top: 22px; }
      @media (max-width: 1919px) {
        .footer__col__wrap {
          padding-top: calc(0.01146 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__col__wrap {
          padding-top: 0px; } }
    @media (max-width: 991px) {
      .footer__col.footer-logo {
        flex-direction: row; } }
  .footer__item {
    margin-bottom: 12px; }
    @media (max-width: 1919px) {
      .footer__item {
        margin-bottom: calc(0.00625 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__item {
        margin-bottom: 20px; } }
    .footer__item.more-margin {
      margin-bottom: 21px; }
      @media (max-width: 1919px) {
        .footer__item.more-margin {
          margin-bottom: calc(0.01094 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__item.more-margin {
          margin-bottom: 17px; } }
    .footer__item:hover span {
      color: #E64569; }
  .footer__logo {
    margin-bottom: 15px; }
    @media (max-width: 1919px) {
      .footer__logo {
        margin-bottom: calc(0.00781 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__logo {
        margin-bottom: 0;
        margin-right: calc(0.01765 * 100vw + 22.35294px); } }
    .footer__logo img {
      width: 273px;
      aspect-ratio: 273/141; }
      @media (max-width: 1919px) {
        .footer__logo img {
          width: calc(0.14219 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__logo img {
          width: calc(0.10417 * 100vw + 66.66667px); } }
    .footer__logo__text {
      font-family: 'PT Sans';
      font-size: 18px;
      line-height: 115%;
      color: #fff;
      max-width: 280px; }
      @media (max-width: 1919px) {
        .footer__logo__text {
          max-width: calc(0.14583 * 100vw + 0px);
          font-size: calc(0.00937 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__logo__text {
          max-width: 50%;
          font-size: calc(0.00417 * 100vw + 8.66667px);
          line-height: calc(0.00417 * 100vw + 11.66667px);
          margin-top: 8px; } }
  .footer__text {
    font-family: 'Golos';
    font-size: 21px;
    line-height: 125%;
    color: rgba(255, 255, 255, 0.6); }
    @media (max-width: 1919px) {
      .footer__text {
        font-size: calc(0.01094 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__text {
        font-size: 14px; } }
    .footer__text-bold {
      font-family: 'Golos';
      font-size: 21px;
      line-height: 125%;
      color: #fff; }
      @media (max-width: 1919px) {
        .footer__text-bold {
          font-size: calc(0.01094 * 100vw + 0px); } }
  .footer__phone {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    @media (max-width: 1919px) {
      .footer__phone {
        margin-bottom: calc(0.01562 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__phone {
        margin-bottom: 20px; } }
    .footer__phone a {
      font-family: 'Golos';
      font-weight: 600;
      font-size: 27px;
      line-height: 125%;
      color: #fff;
      margin-bottom: 3px;
      transition: all .3s ease; }
      .footer__phone a:hover {
        color: #E64569; }
      @media (max-width: 1919px) {
        .footer__phone a {
          font-size: calc(0.01406 * 100vw + 0px);
          margin-bottom: calc(0.00156 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__phone a {
          font-size: 26px;
          margin-bottom: 5px; } }
    .footer__phone span {
      font-family: 'Golos';
      font-weight: 400;
      font-size: 21px;
      line-height: 125%; }
      @media (max-width: 1919px) {
        .footer__phone span {
          font-size: calc(0.01094 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__phone span {
          font-size: 14px; } }
  .footer__vk {
    margin-bottom: 19px; }
    @media (max-width: 1919px) {
      .footer__vk {
        margin-bottom: calc(0.0099 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__vk {
        margin-bottom: 10px; } }
    .footer__vk:hover svg {
      fill: #E64569; }
    .footer__vk svg {
      width: 40px;
      height: 40px;
      fill: #fff;
      margin-right: 2px;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .footer__vk svg {
          width: calc(0.02083 * 100vw + 0px);
          height: calc(0.02083 * 100vw + 0px);
          margin-right: calc(0.00104 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__vk svg {
          width: 15px;
          height: 15px; } }
    .footer__vk span {
      font-family: 'Golos';
      font-size: 21px;
      line-height: 125%;
      color: rgba(255, 255, 255, 0.8); }
      @media (max-width: 1919px) {
        .footer__vk span {
          font-size: calc(0.01094 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__vk span {
          font-size: 14px; } }
  .footer__address {
    margin-bottom: 21px; }
    @media (max-width: 1919px) {
      .footer__address {
        margin-bottom: calc(0.01094 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__address {
        margin-bottom: 10px; } }
    .footer__address a:hover span {
      text-decoration: none; }
    .footer__address span {
      font-family: 'Golos';
      font-size: 24px;
      line-height: 125%;
      color: #fff;
      text-decoration: underline; }
      @media (max-width: 1919px) {
        .footer__address span {
          font-size: calc(0.0125 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer__address span {
          font-size: 14px; } }
  @media (max-width: 991px) {
    .footer__mail {
      margin-bottom: 20px; } }
  .footer__mail span {
    font-family: 'Golos';
    font-size: 21px;
    line-height: 125%;
    color: rgba(255, 255, 255, 0.8); }
    .footer__mail span:hover {
      color: #E64569; }
    @media (max-width: 1919px) {
      .footer__mail span {
        font-size: calc(0.01094 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer__mail span {
        font-size: 14px; } }

.footer-bottom {
  display: grid;
  grid-template-columns: 446px 198px 317px 290px 273px;
  column-gap: 70px;
  align-items: end;
  margin-top: 25px;
  padding-bottom: 31px;
  position: relative; }
  @media (max-width: 1919px) {
    .footer-bottom {
      grid-template-columns: calc(0.23229 * 100vw + 0px) calc(0.10312 * 100vw + 0px) calc(0.1651 * 100vw + 0px) calc(0.15104 * 100vw + 0px) calc(0.14219 * 100vw + 0px);
      margin-top: calc(0.01302 * 100vw + 0px);
      column-gap: calc(0.03646 * 100vw + 0px);
      padding-bottom: calc(0.01615 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .footer-bottom {
      grid-template-columns: 1fr;
      margin-top: 100px;
      row-gap: 11px;
      padding-bottom: 20px; } }
  .footer-bottom::before {
    width: 200vw;
    height: 1px;
    display: block;
    background-color: #fff;
    z-index: 1;
    top: -25px;
    margin-left: -50vw;
    position: absolute; }
    @media (max-width: 991px) {
      .footer-bottom::before {
        content: ''; } }
  .footer-bottom__item {
    display: flex;
    align-items: end; }
    .footer-bottom__item span {
      font-family: 'Golos';
      font-size: 19.5px;
      line-height: 125%;
      color: rgba(255, 255, 255, 0.6); }
      @media (max-width: 1919px) {
        .footer-bottom__item span {
          font-size: calc(0.01016 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer-bottom__item span {
          font-size: 10px; } }
    .footer-bottom__item a span {
      font-family: 'Golos';
      font-size: 19.5px;
      line-height: 125%;
      text-decoration: underline;
      color: rgba(255, 255, 255, 0.6);
      transition: color .3s ease; }
      @media (max-width: 1919px) {
        .footer-bottom__item a span {
          font-size: calc(0.01016 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer-bottom__item a span {
          font-size: 10px; } }
    .footer-bottom__item a svg {
      width: 185px;
      height: 32px;
      margin-left: -5px;
      margin-bottom: -3px;
      fill: white;
      transition: fill .3s ease; }
      @media (max-width: 1919px) {
        .footer-bottom__item a svg {
          width: calc(0.09635 * 100vw + 0px);
          height: calc(0.01667 * 100vw + 0px);
          margin-left: calc(-0.0026 * 100vw + 0px);
          margin-bottom: calc(-0.00156 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer-bottom__item a svg {
          width: 60px;
          height: 11px;
          margin-left: 0px;
          margin-bottom: -2px; } }
    .footer-bottom__item a:hover span {
      color: #E64569; }
    .footer-bottom__item a:hover svg {
      fill: #E64569; }
  .footer-bottom__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 12px;
    height: 69px;
    width: 100%;
    cursor: pointer;
    transition: all .3s ease; }
    @media (max-width: 1919px) {
      .footer-bottom__btn {
        border-radius: calc(0.00625 * 100vw + 0px);
        height: calc(0.03594 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .footer-bottom__btn {
        display: none;
        height: 41px;
        border-radius: 5px; } }
    .footer-bottom__btn.mob {
      display: none; }
      @media (max-width: 991px) {
        .footer-bottom__btn.mob {
          display: flex; } }
    .footer-bottom__btn span {
      font-family: 'Golos';
      font-size: 24px;
      line-height: 125%;
      color: #fff;
      text-align: center;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .footer-bottom__btn span {
          font-size: calc(0.0125 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .footer-bottom__btn span {
          font-size: 16px; } }
    .footer-bottom__btn:hover {
      border-color: #E64569; }
      .footer-bottom__btn:hover span {
        color: #E64569; }

@media (max-width: 575px) {
  .footer-ps {
    margin: 20px 0 0; } }

.footer-ps:hover span {
  color: #F15980; }

.footer-ps:hover svg {
  fill: #F15980; }

.footer-ps span {
  font-size: 15px;
  line-height: 20px;
  color: #B6B6B6;
  transition: color 0.3s; }

.footer-ps svg {
  width: 120px;
  height: 25px;
  fill: #B6B6B6;
  transition: fill 0.3s; }

.search-line {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 62px;
  border: 1px solid #000; }
  @media (max-width: 1919px) {
    .search-line {
      grid-template-columns: 1fr calc(0.03229 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .search-line {
      grid-template-columns: 1fr 35px; } }
  .search-line label {
    display: flex;
    align-items: center;
    width: 100%; }
    .search-line label input {
      border: none;
      width: 100%;
      padding-left: 29px;
      margin-bottom: 3px;
      font-size: 21px;
      height: 26.25px; }
      @media (max-width: 1919px) {
        .search-line label input {
          padding-left: calc(0.0151 * 100vw + 0px);
          margin-bottom: calc(0.00156 * 100vw + 0px);
          font-size: calc(0.01094 * 100vw + 0px);
          height: calc(0.01367 * 100vw + 0px); } }
      .search-line label input::placeholder {
        font-size: 21px;
        height: 26.25px; }
        @media (max-width: 1919px) {
          .search-line label input::placeholder {
            font-size: calc(0.01094 * 100vw + 0px);
            height: calc(0.01367 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .search-line input {
      border: none;
      padding-left: 16px;
      margin-bottom: 1px;
      font-size: 12px;
      line-height: 125%;
      height: 34px; } }
  @media (max-width: 991px) {
    .search-line input::placeholder {
      font-size: 12px;
      line-height: 125%; } }
  .search-line button {
    background-color: #000;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-left: 1px solid #000;
    transition: all .3s ease;
    border-radius: 0px; }
    @media (max-width: 991px) {
      .search-line button {
        border-radius: 0px; } }
    .search-line button:hover {
      background-color: transparent; }
      .search-line button:hover svg {
        stroke: #000; }
    .search-line button svg {
      width: 22px;
      height: 22px;
      fill: transparent;
      stroke: #fff;
      margin-bottom: 1px;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .search-line button svg {
          width: calc(0.01146 * 100vw + 0px);
          height: calc(0.01146 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .search-line button svg {
          width: 14px;
          height: 14px; } }

.rc-anchor-alert {
  display: none !important; }

.register-container {
  display: grid;
  grid-template-columns: .495fr 2px 1fr;
  column-gap: 72px;
  position: relative; }
  @media (max-width: 1919px) {
    .register-container {
      grid-template-columns: 0.495fr calc(0.00104 * 100vw + 0px) 1fr;
      column-gap: calc(0.0375 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .register-container {
      grid-template-columns: 1fr;
      row-gap: 20px; } }
  .register-container__stick {
    display: flex;
    align-items: center; }
    .register-container__stick span {
      display: block;
      width: 100%;
      height: 68%;
      background-color: #AFAFAF; }
      @media (max-width: 991px) {
        .register-container__stick span {
          display: none; } }

.register-natural {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  box-shadow: 1.46px 1.46px 8.78px rgba(0, 0, 0, 0.16);
  padding: 68px 58px;
  position: relative;
  width: 100%;
  overflow: hidden; }
  @media (max-width: 1919px) {
    .register-natural {
      border-radius: calc(0.00729 * 100vw + 0px);
      box-shadow: calc(0.00076 * 100vw + 0px) calc(0.00076 * 100vw + 0px) calc(0.00457 * 100vw + 0px) rgba(0, 0, 0, 0.16);
      padding: calc(0.03542 * 100vw + 0px) calc(0.03021 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .register-natural {
      border-radius: 5px;
      box-shadow: 0.63px 0.63px 3.79px rgba(0, 0, 0, 0.16); } }
  .register-natural__data {
    margin-bottom: 42px; }
    @media (max-width: 1919px) {
      .register-natural__data {
        margin-bottom: calc(0.02187 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-natural__data {
        margin-bottom: 20px; } }
  .register-natural__access {
    margin-bottom: 20px; }
    @media (max-width: 1919px) {
      .register-natural__access {
        margin-bottom: calc(0.01042 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-natural__access {
        margin-bottom: 10px; } }
  .register-natural button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #FF1493;
    border: 1px solid #FF1493;
    border-radius: 11px;
    transition: all .3s ease;
    margin-bottom: 22px;
    width: 100%;
    height: 68px; }
    @media (max-width: 1919px) {
      .register-natural button {
        height: calc(0.03542 * 100vw + 0px);
        margin-bottom: calc(0.01146 * 100vw + 0px);
        border-radius: calc(0.00573 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-natural button {
        height: 35px;
        bottom: 10px;
        border-radius: 5px; } }
    .register-natural button:hover {
      background-color: #fff; }
      .register-natural button:hover span {
        color: #FF1493; }
    .register-natural button span {
      font-family: 'Golos';
      font-size: 24px;
      line-height: 125%;
      color: #fff;
      transition: all .3s ease; }
      @media (max-width: 1919px) {
        .register-natural button span {
          font-size: calc(0.0125 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .register-natural button span {
          font-size: 14px; } }
  .register-natural .register-agreement {
    text-align: center; }
  .register-natural .register-login {
    text-align: center; }

.register-title {
  margin-bottom: 45px; }
  @media (max-width: 1919px) {
    .register-title {
      margin-bottom: calc(0.02344 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .register-title {
      margin-bottom: 20px; } }
  .register-title span {
    font-family: 'Golos';
    font-weight: 600;
    font-size: 38px;
    line-height: 115%; }
    @media (max-width: 1919px) {
      .register-title span {
        font-size: calc(0.01979 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-title span {
        font-size: 18px; } }

.register-subtitle {
  margin-bottom: 19px; }
  @media (max-width: 1919px) {
    .register-subtitle {
      margin-bottom: 19px; } }
  @media (max-width: 991px) {
    .register-subtitle {
      margin-bottom: 10px; } }
  .register-subtitle span {
    font-family: 'Golos';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 115%; }
    @media (max-width: 1919px) {
      .register-subtitle span {
        font-size: calc(0.01198 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-subtitle span {
        font-size: 14px; } }

.register-agreement {
  margin-bottom: 20px;
  font-family: 'PT Sans';
  font-size: 17px;
  line-height: 115%; }
  @media (max-width: 1919px) {
    .register-agreement {
      font-size: calc(0.00885 * 100vw + 0px);
      margin-bottom: calc(0.01042 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .register-agreement {
      font-size: 12px;
      margin-bottom: 10px; } }
  .register-agreement a {
    color: #FF1493;
    text-decoration: underline; }

.register-login {
  font-family: 'Golos';
  font-size: 24px;
  line-height: 125%;
  text-decoration-line: underline;
  color: #0F20E5; }
  @media (max-width: 1919px) {
    .register-login {
      font-size: calc(0.0125 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .register-login {
      font-size: 14px; } }
  .register-login:hover {
    text-decoration: none; }

.register-legal {
  padding: 58px 117px 82px 87px;
  box-shadow: 1.46px 1.46px 8.78px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  height: fit-content;
  margin: auto 0; }
  @media (max-width: 1919px) {
    .register-legal {
      padding: calc(0.03021 * 100vw + 0px) calc(0.06094 * 100vw + 0px) calc(0.04271 * 100vw + 0px) calc(0.04531 * 100vw + 0px);
      box-shadow: calc(0.00076 * 100vw + 0px) calc(0.00076 * 100vw + 0px) calc(0.00457 * 100vw + 0px) rgba(0, 0, 0, 0.16);
      border-radius: calc(0.00729 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .register-legal {
      padding: 10px;
      box-shadow: 0.63px 0.63px 3.79px rgba(0, 0, 0, 0.16);
      border-radius: 5px; } }
  .register-legal__data-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 44px; }
    @media (max-width: 1919px) {
      .register-legal__data-cols {
        column-gap: calc(0.02292 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-legal__data-cols {
        grid-template-columns: 1fr;
        row-gap: 15px; } }
    .register-legal__data-cols:nth-child(3) {
      margin-bottom: 43px; }
      @media (max-width: 1919px) {
        .register-legal__data-cols:nth-child(3) {
          margin-bottom: calc(0.0224 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .register-legal__data-cols:nth-child(3) {
          margin-bottom: 0px;
          margin-bottom: 20px; } }
  .register-legal__col-item .theme-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    .register-legal__col-item .theme-input svg {
      width: 12px;
      height: 12px;
      stroke: #6B6B6B;
      fill: transparent; }
      @media (max-width: 1919px) {
        .register-legal__col-item .theme-input svg {
          width: calc(0.00625 * 100vw + 0px);
          height: calc(0.00625 * 100vw + 0px); } }
    .register-legal__col-item .theme-input__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%; }
    .register-legal__col-item .theme-input__dropdown {
      position: absolute;
      width: fit-content;
      min-width: calc(100% + 2px);
      top: calc(100% + 1px);
      left: -1px;
      z-index: 2;
      display: none;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 1.5px 1.5px 9px 0 #00000029;
      border-radius: 0 0 10px 10px;
      overflow: hidden; }
      .register-legal__col-item .theme-input__dropdown.opened {
        display: flex; }
      .register-legal__col-item .theme-input__dropdown span {
        cursor: pointer;
        padding: 10px;
        width: 100%;
        transition: all .3s ease; }
        .register-legal__col-item .theme-input__dropdown span:hover {
          background-color: #f9f9f9;
          color: #FF1493; }
  .register-legal__submit {
    display: grid;
    grid-template-columns: 1fr 415px;
    margin-top: 59px;
    column-gap: 43px;
    row-gap: 18px;
    align-items: center;
    padding-right: 35px; }
    @media (max-width: 1919px) {
      .register-legal__submit {
        grid-template-columns: 1fr calc(0.21615 * 100vw + 0px);
        margin-top: calc(0.03073 * 100vw + 0px);
        column-gap: calc(0.0224 * 100vw + 0px);
        row-gap: calc(0.00937 * 100vw + 0px);
        padding-right: calc(0.01823 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-legal__submit {
        grid-template-columns: 1fr;
        margin-top: 10px;
        row-gap: 5px;
        padding-right: 0px; } }
    .register-legal__submit__btn {
      border: 1px solid #FF1493;
      border-radius: 11px;
      background-color: #FF1493;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;
      cursor: pointer; }
      @media (max-width: 1919px) {
        .register-legal__submit__btn {
          border-radius: calc(0.00573 * 100vw + 0px);
          height: calc(0.03542 * 100vw + 0px); } }
      @media (max-width: 991px) {
        .register-legal__submit__btn {
          border-radius: 5px;
          height: 35px; } }
      .register-legal__submit__btn:hover {
        background-color: #fff;
        border-color: #FF1493; }
        .register-legal__submit__btn:hover span {
          color: #FF1493; }
      .register-legal__submit__btn span {
        font-family: 'Golos';
        font-size: 24px;
        line-height: 125%;
        color: #fff;
        transition: all .3s ease; }
        @media (max-width: 1919px) {
          .register-legal__submit__btn span {
            font-size: calc(0.0125 * 100vw + 0px); } }
        @media (max-width: 991px) {
          .register-legal__submit__btn span {
            font-size: 14px; } }
    .register-legal__submit__presonal {
      text-align: end; }
      @media (max-width: 991px) {
        .register-legal__submit__presonal {
          text-align: center; } }
    .register-legal__submit__login {
      grid-column: 1/-1;
      margin-left: auto; }
      @media (max-width: 991px) {
        .register-legal__submit__login {
          margin: auto; } }

.register-checkbox {
  display: flex;
  align-items: start;
  margin-top: 21px; }
  @media (max-width: 1919px) {
    .register-checkbox {
      margin-top: calc(0.01094 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .register-checkbox {
      margin-top: 5px; } }
  .register-checkbox:hover div {
    border-color: #FF1493; }
  .register-checkbox.error span {
    color: #f00; }
  .register-checkbox input {
    display: none; }
    .register-checkbox input:checked + div {
      border-color: #FF1493; }
      .register-checkbox input:checked + div span {
        display: block; }
  .register-checkbox div {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 5px;
    margin-right: 11px;
    padding: 0 0 2px 0; }
    .register-checkbox div span {
      display: none; }
    @media (max-width: 1919px) {
      .register-checkbox div {
        width: calc(0.01198 * 100vw + 0px);
        height: calc(0.01198 * 100vw + 0px);
        border-radius: calc(0.0026 * 100vw + 0px);
        margin-right: calc(0.00573 * 100vw + 0px);
        padding: 0 0 calc(0.00104 * 100vw + 0px) 0; } }
    @media (max-width: 991px) {
      .register-checkbox div {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        margin-right: 10px;
        padding: 0 0 2px 0;
        margin-top: 3px; }
        .register-checkbox div span {
          width: 13px;
          height: 8px;
          transform: rotate(-45deg);
          border-bottom: 2px solid #FF1493;
          border-left: 2px solid #FF1493; } }
    @media (max-width: 991px) and (max-width: 1919px) {
      .register-checkbox div span {
        width: calc(0.00677 * 100vw + 0px);
        height: calc(0.00417 * 100vw + 0px); } }
    @media (max-width: 991px) and (max-width: 991px) {
      .register-checkbox div span {
        width: 10px;
        height: 6px; } }
  .register-checkbox span {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 115%; }
    @media (max-width: 1919px) {
      .register-checkbox span {
        font-size: calc(0.01042 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .register-checkbox span {
        font-size: 14px; } }

.login-block label {
  width: 512px; }
  @media (max-width: 1919px) {
    .login-block label {
      width: calc(0.26667 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .login-block label {
      width: 100%; } }

.login-block__button {
  display: grid;
  grid-template-columns: 512px 1fr;
  align-items: center;
  column-gap: 30px; }
  @media (max-width: 1919px) {
    .login-block__button {
      grid-template-columns: calc(0.26667 * 100vw + 0px) 1fr;
      column-gap: calc(0.01562 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .login-block__button {
      grid-template-columns: 1fr; } }
  @media (max-width: 991px) {
    .login-block__button p {
      width: 100%;
      text-align: center; } }

.login-block button {
  width: 512px; }
  @media (max-width: 1919px) {
    .login-block button {
      width: calc(0.26667 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .login-block button {
      width: 100%; } }

.login-info {
  margin: 0 0 20px; }
  .login-info__text {
    font-size: 15px;
    line-height: 24px;
    color: #000; }

.login-form {
  position: relative; }

.login-forgot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 35px 0 0;
  width: 512px;
  align-items: center; }
  @media (max-width: 1919px) {
    .login-forgot {
      margin: calc(0.01823 * 100vw + 0px) 0 0;
      width: calc(0.26667 * 100vw + 0px); } }
  @media (max-width: 991px) {
    .login-forgot {
      width: 100%; } }
  .login-forgot a {
    color: #0F20E5;
    text-decoration: underline; }
  .login-forgot span {
    color: #000; }
  .login-forgot > a, .login-forgot > span {
    font-size: 24px;
    line-height: 125%; }
    @media (max-width: 1919px) {
      .login-forgot > a, .login-forgot > span {
        font-size: calc(0.0125 * 100vw + 0px); } }
    @media (max-width: 991px) {
      .login-forgot > a, .login-forgot > span {
        font-size: 14px; } }
    .login-forgot > a > a, .login-forgot > span > a {
      color: #0F20E5;
      text-decoration: underline; }
