@import 'base/app';

.login-container{
    
}
.rc-anchor-alert{
    display: none!important;
}
.register-container{
    display: grid;
    grid-template-columns: .495fr 2px 1fr;
    column-gap: 72px;
    position: relative;
    @include _1920{
        grid-template-columns: .495fr fluid_1280(2) 1fr;
        column-gap: fluid_1280(72);
    }
    @include _992{
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
    &__stick{
        display: flex;
        align-items: center;
        span{
            display: block;
            width: 100%;
            height: 68%;
            background-color:#AFAFAF;;
            @include _992{
                display: none;
            }
        }
    }
}
.register-natural{
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    box-shadow: 1.46px 1.46px 8.78px rgba(0, 0, 0, 0.16);
    padding: 68px 58px;
    position: relative;
    width: 100%;
    overflow: hidden;
    @include _1920{
        border-radius: fluid_1280(14);
        box-shadow: fluid_1280(1.46) fluid_1280(1.46) fluid_1280(8.78) rgba(0, 0, 0, 0.16);
        padding: fluid_1280(68) fluid_1280(58);
    }
    @include _992{
        border-radius: 5px;
        box-shadow: 0.63px 0.63px 3.79px rgba(0, 0, 0, 0.16);
    }
    &__data{
        margin-bottom: 42px;
        @include _1920{
            margin-bottom: fluid_1280(42);
        }
        @include _992{
            margin-bottom: 20px;
        }
    }
    &__access{
        margin-bottom: 20px;
        @include _1920{
            margin-bottom: fluid_1280(20);
        }
        @include _992{
            margin-bottom: 10px;
        }
    }
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #FF1493;
        border: 1px solid #FF1493;
        border-radius: 11px;
        transition: all .3s ease;
        margin-bottom: 22px;
        width: 100%;
        height: 68px;
        @include _1920{
            height: fluid_1280(68);
            margin-bottom: fluid_1280(22);
            border-radius: fluid_1280(11);
        }
        @include _992{
            height: 35px;
            bottom: 10px;
            border-radius: 5px;
        }
        &:hover{
            background-color: #fff;
            span{
                color: #FF1493;
            }
        }
        span{
            font-family: 'Golos';
            font-size: 24px;
            line-height: 125%;
            color: #fff;
            transition: all .3s ease;
            @include _1920{
                font-size: fluid_1280(24);
            }
            @include _992{
                font-size: 14px;
            }
        }
    }
    .register-agreement{
        text-align: center;
    }
    .register-login{
        text-align: center;
    }
}
.register-title{
    margin-bottom: 45px;
    @include _1920{
        margin-bottom: fluid_1280(45);
    }
    @include _992{
        margin-bottom: 20px;
    }
    span{
        font-family: 'Golos';
        font-weight: 600;
        font-size: 38px;
        line-height: 115%;
        @include _1920{
            font-size: fluid_1280(38);
        }
        @include _992{
            font-size: 18px;
        }
    }
}
.register-subtitle{
    margin-bottom: 19px;
    @include _1920{
        margin-bottom: 19px;
    }
    @include _992{
        margin-bottom: 10px;
    }
    span{
        font-family: 'Golos';
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 115%;
        @include _1920{
            font-size: fluid_1280(23);
        }
        @include _992{
            font-size: 14px;
        }
    }
}
.register-agreement{
    margin-bottom: 20px;
    font-family: 'PT Sans';
    font-size: 17px;
    line-height: 115%;
    @include _1920{
        font-size: fluid_1280(17);
        margin-bottom: fluid_1280(20);
    }
    @include _992{
        font-size: 12px;
        margin-bottom: 10px;
    }
    a{
        color: #FF1493;
        text-decoration: underline;
    }
}
.register-login{
    font-family: 'Golos';
    font-size: 24px;
    line-height: 125%;
    text-decoration-line: underline;
    color: #0F20E5;
    @include _1920{
        font-size: fluid_1280(24);
    }
    @include _992{
        font-size: 14px;
    }
    &:hover{
        text-decoration: none;
    }
}
.register-legal{
    padding: 58px 117px 82px 87px;
    box-shadow: 1.46px 1.46px 8.78px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    height: fit-content;
    margin: auto 0;
    @include _1920{
        padding: fluid_1280(58) fluid_1280(117) fluid_1280(82) fluid_1280(87);
        box-shadow: fluid_1280(1.46) fluid_1280(1.46) fluid_1280(8.78) rgba(0, 0, 0, 0.16);
        border-radius: fluid_1280(14);
    }
    @include _992{
        padding: 10px;
        box-shadow: 0.63px 0.63px 3.79px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
    }
    &__data-cols{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 44px;
        @include _1920{
            column-gap: fluid_1280(44);
        }
        @include _992{
            grid-template-columns: 1fr;
            row-gap: 15px;
        }
        &:nth-child(3){
            margin-bottom: 43px;
            @include _1920{
                margin-bottom: fluid_1280(43);
            }
            @include _992{
                margin-bottom: 0px;
                margin-bottom: 20px;
            }
        }
    }
    &__col-item{

        .theme-input{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            svg{
                width: 12px;
                height: 12px;
                stroke: #6B6B6B;
                fill: transparent;
                @include _1920{
                    width: fluid_1280(12);
                    height: fluid_1280(12);
                }
            }
            &__container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 95%;
            }
            &__dropdown{
                position: absolute;
                width: fit-content;
                min-width: calc(100% + 2px);
                top: calc(100% + 1px);
                left: -1px;
                z-index: 2;
                display: none;
                flex-direction: column;
                background-color: #fff;
                box-shadow: 1.5px 1.5px 9px 0 #00000029;
                border-radius: 0 0 10px 10px;
                overflow: hidden;
                &.opened{
                    display: flex;
                }
                span{
                    cursor: pointer;
                    padding: 10px;
                    width: 100%;
                    transition: all .3s ease;
                    &:hover{
                        background-color: #f9f9f9;
                        color: #FF1493;
                    }

                }
            }
        }
    }
    &__submit{
        display: grid;
        grid-template-columns: 1fr 415px;
        margin-top: 59px;
        column-gap: 43px;
        row-gap: 18px;
        align-items: center;
        padding-right: 35px;
        @include _1920{
            grid-template-columns: 1fr fluid_1280(415);
            margin-top: fluid_1280(59);
            column-gap: fluid_1280(43);
            row-gap: fluid_1280(18);
            padding-right: fluid_1280(35);
        }
        @include _992{
            grid-template-columns: 1fr;
            margin-top: 10px;
            row-gap: 5px;
            padding-right: 0px;
        }

        &__btn{
            border: 1px solid #FF1493;
            border-radius: 11px;
            background-color: #FF1493;
            height: 68px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease;
            cursor: pointer;
            @include _1920{
                border-radius: fluid_1280(11);
                height: fluid_1280(68);
            }
            @include _992{
                border-radius: 5px;
                height: 35px;

            }
            &:hover{
                background-color: #fff;
                border-color: #FF1493;
                span{
                    color: #FF1493;
                }
            }
            span{
                font-family: 'Golos';
                font-size: 24px;
                line-height: 125%;
                color: #fff;
                transition: all .3s ease;
                @include _1920{
                    font-size: fluid_1280(24);
                }
                @include _992{
                    font-size: 14px;
                }
            }
        }
        &__presonal{
            text-align: end;
            @include _992{
                text-align: center;
            }
        }
        &__login{
            grid-column: 1/-1;
            margin-left: auto;
            @include _992{
                margin: auto;
            }
        }
    }
}
.register-checkbox{
    display: flex;
    align-items: start;
    margin-top: 21px;
    @include _1920{
        margin-top: fluid_1280(21);
    }
    @include _992{
        margin-top: 5px;
    }
    &:hover{
        div{
            border-color: #FF1493;
        }
    }
    &.error{
        span{
            color: #f00;
        }
    }
    input{
        display: none;
        &:checked + div{
            border-color: #FF1493;
            span{
                display: block;
            }
        }
    }
    div{
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000;
        border-radius: 5px;
        margin-right: 11px;
        padding: 0 0 2px 0;
        // transition: border .3s ease;
        span{
            display: none;
        }
        @include _1920{
            width: fluid_1280(23);
            height: fluid_1280(23);
            border-radius: fluid_1280(5);
            margin-right: fluid_1280(11);
            padding: 0 0 fluid_1280(2) 0;
        }
        @include _992{
            width: 16px;
            height: 16px;
            border-radius: 5px;
            margin-right: 10px;
            padding: 0 0 2px 0;
            margin-top: 3px;
            span{
                width: 13px;
                height: 8px;
                transform: rotate(-45deg);
                border-bottom: 2px solid #FF1493;
                border-left: 2px solid #FF1493;
                @include _1920{
                    width: fluid_1280(13);
                    height: fluid_1280(8);
                }
                @include _992{
                    width: 10px;
                    height: 6px;
                }
            }
        }
    }
    span{
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 115%;
        @include _1920{
            font-size: fluid_1280(20);
        }
        @include _992{
            font-size: 14px;
        }
    }
}

.login-block{
    label{
        width: 512px;
        @include _1920{
            width: fluid_1280(512);
        }
        @include _992{
            width: 100%;
        }
    }
    &__button{
        display: grid;
        grid-template-columns: 512px 1fr;
        align-items: center;
        column-gap: 30px;
        @include _1920{
            grid-template-columns: fluid_1280(512) 1fr;
            column-gap: fluid_1280(30);
        }
        @include _992{
            grid-template-columns: 1fr;
        }
        p{
            @include _992{
                width: 100%;
                text-align: center;
            }
        }
    }
    button{
        width: 512px;
        @include _1920{
            width: fluid_1280(512);
        }
        @include _992{
            width: 100%;
        }
    }
}

.login-info{
    margin: 0 0 20px;
    &__text{
        font-size: 15px;
        line-height: 24px;
        color: $text-clr;
    }
}
.login-form{
    position: relative;
}

.login-forgot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 35px 0 0;
    width: 512px;
    align-items: center;
    @include _1920{
        margin: fluid_1280(35) 0 0;
        width: fluid_1280(512);
    }
    @include _992{
        width: 100%;
    }
    a{
        color: #0F20E5;
        text-decoration: underline;
    }
    span{
        color: #000;
    }
    > a, >span{
        font-size: 24px;
        line-height: 125%;
        @include _1920{
            font-size: fluid_1280(24);
        }
        @include _992{
            font-size: 14px;
        }
        > a{
            color: #0F20E5;
            text-decoration: underline;
        }
    }
}
